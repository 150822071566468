<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.SEND_TO_ARCHIVE.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-send-to-archive-dialog-content">
  <form [formGroup]="archiveForm" novalidate>
    <div class="form-column">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.SEND_TO_ARCHIVE.ARCHIVE' | translate }}</mat-label>
        <mat-select formControlName="selectedArchive" required>
          <mat-option *ngFor="let archive of archivesFiltered" [value]="archive.id">{{ archive.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="selectedArchive.invalid">{{ 'APP.ARCHIVE.SEND_TO_ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.SEND_TO_ARCHIVE.DATE_UNTIL' | translate }}</mat-label>
        <input matInput [matDatepicker]="dateArchivingPicker" [min]="today" formControlName="archiveUntil" name="archiveUntil" readonly />
        <mat-datepicker-toggle matSuffix [for]="dateArchivingPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateArchivingPicker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-column">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.SEND_TO_ARCHIVE.MESSAGE' | translate }}</mat-label>
        <textarea matInput formControlName="message" name="message" placeholder="Enter additional message..."></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>

  <button
    mat-button
    class="adf-dialog-action-button"
    color="primary"
    (click)="sendToArchive()">
    {{ 'APP.ARCHIVE.SEND_TO_ARCHIVE.SEND' | translate }}
  </button>
  <button
    mat-button
    mat-dialog-close>
    {{ 'APP.GENERAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
