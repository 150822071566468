import { AppStore, SnackbarErrorAction, getUserProfile } from '@alfresco/aca-shared/store';
import { Node } from '@alfresco/js-api';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NeocomDocumentSelectorComponent } from '../../neocom-document-selector/neocom-document-selector.component';
import { ContentService } from '@alfresco/adf-content-services';
import { ContentApiService, LleidaService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent } from '@alfresco/aca-shared';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { TranslationService } from '@alfresco/adf-core';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NeocomDocumentSelectorComponent,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    MatInputModule
  ],
  templateUrl: './lleida-signature.component.html',
  styleUrls: ['./lleida-signature.component.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-lleida-signature',
})
export class LleidaSignatureComponent implements OnInit {

  base64Content: string;
  userName: string;
  selectedDocuments: Node[] = [];
  initiallySelectedDocuments: Node[] = [];
  lleidaDefinitions: any[] = [];

  lleidaForm = new FormGroup({
    lleidaDefinition: new FormControl(undefined),
    name: new FormControl(undefined, Validators.required),
    surname: new FormControl(undefined, Validators.required),
    email: new FormControl(undefined, Validators.required),
    phone: new FormControl(undefined),
  });

  @ViewChild(NeocomDocumentSelectorComponent)
  documentSelector: NeocomDocumentSelectorComponent;

  constructor(
    private store: Store<AppStore>,
    private contentApi: ContentApiService,
    private contentService: ContentService,
    private translation: TranslationService,
    private route: ActivatedRoute,
    private lleidaService: LleidaService
  ) { }

  async ngOnInit() {
    this.store.select(getUserProfile).subscribe((user) => (this.userName = user.id));

    if (this.route.snapshot.queryParams.nodeId) {
      try {
        const node = await this.contentApi.getNodeInfo(this.route.snapshot.queryParams.nodeId).toPromise();
        this.initiallySelectedDocuments = [ node ];
      } catch (_) {
        return this.showSnackbarError('APP.LLEIDA.LLEIDA_SIGN.NODE_INFO_ERROR');
      }
    }

    this.lleidaForm.get('lleidaDefinition').setValidators(Validators.required);
    await this.fetchSignatureTypes();
  }

  async fetchSignatureTypes() {
    try {
      this.lleidaDefinitions = await this.lleidaService.getSignatureTypeDefinitions('neocom', 'VCoWdNbz1GSD5qEjdgvJUSJ2mUFpHjRf');
    } catch (error) {
      console.error('Error fetching signature types:', error);
    }
  }

  showSnackbarError(translationKey: string) {
    this.store.dispatch(new SnackbarErrorAction(this.translation.instant(translationKey)));
  }

  shouldShowPhoneNumberSection(): boolean {
    const selectedConfig = this.lleidaForm.get('lleidaDefinition').value;
    return selectedConfig && selectedConfig.config_id === '69555';
  }

  async initiateSignature() {
    if (this.selectedDocuments.length === 0) {
      return this.showSnackbarError('APP.LLEIDA.LLEIDA_SIGN.MISSING_DOCUMENT');
    }

    const selectedDocument = this.selectedDocuments[0];
    const selectedDocumentId = this.selectedDocuments[0].id;
    const contentUrl = this.contentService.getContentUrl(selectedDocumentId);

    if (!contentUrl) {
      return console.error('Failed to get content URL');
    }

    try {
      const base64Content = await this.lleidaService.convertUrlToBase64(contentUrl);
      const name = this.lleidaForm.get('name').value;
      const surname = this.lleidaForm.get('surname').value;
      const email = this.lleidaForm.get('email').value;
      const phone = this.lleidaForm.get('phone').value;
      const selectedConfigId = this.lleidaForm.get('lleidaDefinition').value;

      const response = await this.lleidaService.startSignature(
        'neocom',
        'VCoWdNbz1GSD5qEjdgvJUSJ2mUFpHjRf',
        selectedDocument,
        base64Content,
        name,
        surname,
        email,
        selectedConfigId,
        phone
      );

      if (response && response.code === 200) {
        await this.lleidaService.addLleidaTag(selectedDocument.id);
        console.log('Signature initiation successful:', response);
      } else if (response && response.code === 1601) {
        return this.showSnackbarError('APP.LLEIDA.LLEIDA_SIGN.INSUFFICIENT_CREDITS');
      } else {
        console.warn('Signature initiation failed. Response code:', response.code);
      }

      this.onFormReset(null);
    } catch (error) {
      console.error('Error initiating signature:', error);
    }
  }

  onDocumentSelectorSelectionChanged(newSelection: Node[]) {
    this.selectedDocuments = newSelection;
  }

  onDocumentSelectorNavigate(index: number) {
    const node = this.selectedDocuments[index];
    const url = `#/libraries/${node.parentId}/(viewer:view/${node.id})?location=%2Flibraries%2F${node.parentId}`;
    window.open(url, '_blank');
  }

  onFormSubmit() {
    const name = this.lleidaForm.get('name').value;
    const surname = this.lleidaForm.get('surname').value;
    const email = this.lleidaForm.get('email').value;

    if (!name) {
      return this.showSnackbarError('APP.LLEIDA.LLEIDA_SIGN.MISSING_NAME');
    }

    if (!surname) {
      return this.showSnackbarError('APP.LLEIDA.LLEIDA_SIGN.MISSING_SURNAME');
    }

    if (!email) {
      return this.showSnackbarError('APP.LLEIDA.LLEIDA_SIGN.MISSING_EMAIL');
    }

    this.initiateSignature();
  }

  onFormReset(event: any) {
    this.lleidaForm.reset();
    this.documentSelector.onRemoveAll(event);
  }

  get lleidaDefinition() {
    return this.lleidaForm.get('lleidaDefinition').value;
  }
}