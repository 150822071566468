import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent, SiteService, debounce } from '@alfresco/aca-shared';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { Utils } from '../../workflow/utils';
import { TranslationService } from '@alfresco/adf-core';
import { CustomReportsService } from '@alfresco/aca-shared';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BreadcrumbModule,
    PageLayoutComponent,
    PageLayoutContentComponent,
    PageLayoutHeaderComponent,
    MatFormFieldModule,
    MatButtonModule,
    MatOptionModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './activity-feed-report.component.html',
  styleUrls: ['./activity-feed-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-activity-feed-report'
})
export class ActivityFeedReportComponent implements OnInit {
  
  sites: any[] = [];
  today = new Date();
  showDataTable: boolean = false;
  showActivityMessage: boolean = false;
  isLoading: boolean = false;
  tableColumns: any[];
  tableRows: any[] = [];

  itemsPerPageOptions: number[] = [10, 25, 50, 75, 100];
  itemsPerPage: number = 50;
  currentPage: number = 1;
  totalPages: number;

  localData: any[] = [];

  activityFeedReportForm = new FormGroup({
    selectedSite: new FormControl(null, Validators.required),
    user: new FormControl(undefined),
    dateFrom: new FormControl(),
    dateTo: new FormControl(),
    itemsPerPage: new FormControl(this.itemsPerPage, Validators.required)
  });
  dataRequestDebounce: Function;

  private nodeRefsMap: Map<number, { nodeRef: string, parentNodeRef: string }> = new Map();
  private formSubmitCalled: boolean = false;
  private requestCounter: number = 0;

  constructor(
    private customReportsService: CustomReportsService,
    private siteService: SiteService,
    private translation: TranslationService)
  {
    this.initializeTableColumns();

    translation.translate.onLangChange.subscribe(() => {
      this.initializeTableColumns();
    });
  }

  async ngOnInit() {
    this.dataRequestDebounce = debounce(300);

    await this.getSites();
  }

  private initializeTableColumns(): void {
    this.tableColumns = [
      { key: 'user', title: this.translation.instant('APP.CUSTOM_REPORTS.ACTIVITY_FEED_REPORT.USER'), type: 'text' },
      { key: 'activityType', title: this.translation.instant('APP.CUSTOM_REPORTS.ACTIVITY_FEED_REPORT.ACTIVITY_TYPE'), type: 'text' },
      { key: 'activitySummary', title: this.translation.instant('APP.CUSTOM_REPORTS.ACTIVITY_FEED_REPORT.ACTIVITY_TARGET'), type: 'text' },
      { key: 'postDate', title: this.translation.instant('APP.CUSTOM_REPORTS.ACTIVITY_FEED_REPORT.TIME'), type: 'text' }
    ];
  }

  generateTable(data: any[], page: number, itemsPerPage: number) {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = data.slice(startIndex, endIndex);

    if (pageData && pageData.length > 0) {
      this.tableRows = pageData.map((item, index) => this.createRow(item, startIndex + index));

      this.showDataTable = true;
      this.showActivityMessage = false;
    } else {
      this.showDataTable = false;
      this.showActivityMessage = true;
    }
    this.isLoading = false;
  }

  resetCurrentPage(): void {
    this.currentPage = 1;
  }

  async getSites() {
    try {
      this.sites = await this.siteService.listSites();
      this.sites.sort((a, b) => a.title.localeCompare(b.title));
      if (this.sites.length > 0) {
        this.activityFeedReportForm.patchValue({
          selectedSite: this.sites[0].id
        });
      }
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  }

  private createRow(item: any, index: number): any {
    const row = {};
    this.tableColumns.forEach((column) => {
      const value = this.extractValue(item, column.key, index);
      row[column.key] = this.formatValue(column, value);
    });
    return row;
  }

  private extractValue(item: any, key: string, index: number): any {
    try {
      const activitySummary = JSON.parse(item.activitySummary);

      if (activitySummary.nodeRef && activitySummary.parentNodeRef) {
        const nodeRef = activitySummary.nodeRef.split('/').pop();
        const parentNodeRef = activitySummary.parentNodeRef.split('/').pop();
        this.nodeRefsMap.set(index, {
          nodeRef: nodeRef,
          parentNodeRef: parentNodeRef
        });
      }

      switch (key) {
        case 'user':
          return `${activitySummary.firstName} ${activitySummary.lastName}`;
        case 'activitySummary':
          return activitySummary.title;
        case 'activityType':
          return this.extractActivityType(item);
        default:
          const keys = key.split('.');
          let value = item;
          keys.forEach((k) => {
            value = value[k];
          });
          return value;
      }
    } catch (error) {
      console.error('Error parsing activitySummary:', error);
      return '';
    }
  }

  private extractActivityType(item: any): string {
    try {
      const activityTypeLastDotIndex = item.activityType.lastIndexOf('.');
      const activityTypeDisplay = item.activityType
        .substring(activityTypeLastDotIndex + 1)
        .toLowerCase()
        .split('-')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      return activityTypeDisplay;
    } catch (error) {
      console.error('Error extracting activityType:', error);
      return '';
    }
  }

  private formatValue(column: any, value: any): any {
    switch (column.key) {
      case 'postDate':
        return this.formatDate(value);
      default:
        return value;
    }
  }

  onActivityClick(rowIndex: number) {
    const globalIndex = (this.currentPage - 1) * this.itemsPerPage + rowIndex;
    const nodeRefs = this.nodeRefsMap.get(globalIndex);
  
    if (nodeRefs) {
      const { nodeRef, parentNodeRef } = nodeRefs;
      const url = `#/libraries/${parentNodeRef}/(viewer:view/${nodeRef})?location=%2Flibraries%2F${parentNodeRef}`;
      window.open(url, '_blank');
    } else {
      console.error('Node references not found.');
    }
  }

  private formatDate(value: any): string {
    const parsedDate = new Date(Date.parse(value));

    if (!isNaN(parsedDate.getTime())) {
      return Utils.formatDateWithTime(parsedDate);
    } else {
      return value;
    }
  }

  printTable() {
    const title = this.translation.instant('APP.CUSTOM_REPORTS.ACTIVITY_FEED_REPORT.TITLE');
    const tableContent = this.generatePrintableTableContent();

    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: center;
            }
            th {
              background-color: #f2f2f2;
            }
            th:nth-child(3), td:nth-child(3) {
              width: 40%;
            }
            th:nth-child(4), td:nth-child(4) {
              width: 100px;
            }
          </style>
        </head>
        <body>
          <h2>${title}</h2>
          ${tableContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  }

  private generatePrintableTableContent(): string {
    let content = '<table><thead><tr>';
  
    this.tableColumns.forEach(column => {
      content += `<th>${column.title}</th>`;
    });
    content += '</tr></thead><tbody>';
  
    this.tableRows.forEach(row => {
      content += '<tr>';
      this.tableColumns.forEach(column => {
        content += `<td>${row[column.key]}</td>`;
      });
      content += '</tr>';
    });
  
    content += '</tbody></table>';
  
    return content;
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.generateTable(this.localData, this.currentPage, this.itemsPerPage);
    }
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.resetCurrentPage();
    this.onFormSubmit();
  }

  async onFormSubmit() {
    this.isLoading = true;
    this.formSubmitCalled = true;
    this.requestCounter++;
    const currentRequest = this.requestCounter;

    try {
      const data = await this.fetchData();
      if (currentRequest === this.requestCounter && this.formSubmitCalled) {
        this.totalPages = Math.ceil(data.length / this.itemsPerPage);
        this.generateTable(data, this.currentPage, this.itemsPerPage);
      }
    } catch (error) {
      console.error('Error generating report:', error);
      this.isLoading = false;
    }
  }

  async fetchData(): Promise<any[]> {
    const formValues = this.activityFeedReportForm.value;

    const selectedSite = formValues.selectedSite;
    const selectedUser = formValues.user;
    const dateFrom = formValues.dateFrom;
    const dateTo = formValues.dateTo;

    try {
      const responseData: any = await this.customReportsService.getSiteActivity(selectedSite, selectedUser, dateFrom, dateTo);
      const data: any[] = responseData.data;
      this.localData = data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  onFormReset(_) {
    this.activityFeedReportForm.reset({
      selectedSite: this.sites.length > 0 ? this.sites[0].id : null,
      itemsPerPage: 50
    });
    this.showDataTable = false;
    this.showActivityMessage = false;
    this.isLoading = false;
    this.formSubmitCalled = false;
    this.resetCurrentPage();
    this.tableRows = [];
    this.localData = [];
  }
}
