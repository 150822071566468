export class Constants {

    // if changing, note duplicates in aca-shared/src/lib/utils/constants.ts
    public static GROUP_ARCHIVE_USERS_ID = "ARCHIVE_USERS"; 
    public static GROUP_ARCHIVE_ADMINS_ID = "ARCHIVE_ADMINS";
    public static GROUP_ARCHIVE_CREATORS_ID = "ARCHIVE_CREATORS";
    public static GROUP_WORKFLOW_REPORTING_ID = "NEOWORKFLOW_WORKFLOW_REPORTING";
    public static GROUP_ACTIVITY_FEED_ID = "NEOWORKFLOW_ACTIVITY_FEED";
    public static GROUP_LLEIDA_ID = "LLEIDA";
    public static GROUP_COMPANY_SIGNATURE_ID = "COMPANY_SIGNATURE";
};