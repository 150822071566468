import { Component, OnInit,ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ArchiveService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction, getUserProfile } from '@alfresco/aca-shared/store';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule} from '@angular/material/sort';
import { DeletePendingDocumentDialogComponent } from '../../../dialogs/delete-pending-document/delete-pending-document.dialog';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { DataCellEvent,DataColumn, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from '@alfresco/adf-content-services';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-pending-documents',
  templateUrl: './pending-documents.component.html',
  styleUrls: ['./pending-documents.component.scss'],
   animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PendingDocumentsComponent implements OnInit {
  @ViewChild(MatPaginatorModule) paginator: MatPaginatorModule;
  @ViewChild(MatSortModule) sort: MatSortModule;

  loading: boolean = false;
  error: boolean = false;
  dataSource: object[];
  dataRows: object[];
  loggedInUserName: string;
  pendingDocumentsSchema: DataColumn[]; 
  
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private archiveService: ArchiveService,
    private store: Store<AppStore>,
    private translation: TranslationService
  ) {
    this.initializePendingDocumentsSchema();

    translation.translate.onLangChange.subscribe(() => {
      this.initializePendingDocumentsSchema();
    });
  }

  private initializePendingDocumentsSchema() {
    this.pendingDocumentsSchema = [
      {
        type: 'text',
        key: 'name',
        title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.DOCUMENT_NAME'),
        sortable: true,
      },
      {
        type: 'text',
        key: 'properties.from',
        title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.SENT_FROM'),
        cssClass: 'full-width',
        sortable: true,
      },
      {
        type: 'text',
        key: 'properties.messageText',
        title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.MESSAGE'),
        cssClass: 'full-width',
        sortable: true,
      },
      {
        type: 'text',
        key: 'properties.untilText',
        title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.DUE_DATE'),
        cssClass: 'full-width',
        sortable: true,
      },
      {
        type: 'text',
        key: 'properties.isReservedText',
        title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.IS_RESEREVED'),
        cssClass: 'full-width',
        sortable: true,
      },
      {
        type: 'text',
        key: 'properties.reservedByUserText',
        title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.RESEREVED_BY'),
        cssClass: 'full-width',
        sortable: true,
      }
   ];
  }

  ngOnInit() {     
    this.store.select(getUserProfile).subscribe((user) => {
      this.loggedInUserName = user.id;
    });
    this.getPendingDocuments();
  }

  getPendingDocuments() {
    this.loading = true;
    this.error = false;
    this.archiveService.getPendingDocuments().then(
      (response: any) => {
        this.dataSource = response.documents.map(el => ({
          ...el,
          properties: {
            ...el.properties,
            messageText: el.properties.message ? el.properties.message : '/',
            untilText: el.properties.until ? el.properties.until : '/',
            isReservedText: this.translation.instant(
              el.properties.isReserved === 'true' ? 'APP.GENERAL.YES' : 'APP.GENERAL.NO'
            ),
            reservedByUserText: el.properties.reservedByUser ? el.properties.reservedByUser : '/',
          }
        }));
        this.dataRows = this.dataSource;
        this.loading = false;
        this.error = false;
      })
      .catch((_) => {
        this.loading = false;
        this.error = true;
        this.store.dispatch(
            new SnackbarErrorAction(
                this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  onShowRowActionsMenu = (event: DataCellEvent) => {
    const previewAction = {
      id: 0,
      title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.PREVIEW')
    };
    const archiveAction = {
      id: 1,
      title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.ARCHIVE_FILE')
    };
    const actAction = {
      id: 2,
      title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.ARCHIVE_ACT')
    };
    const removeAction = {
      id: 3,
      title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.REMOVE')
    };
    const releaseAction = {
      id: 4,
      title: this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.RELEASE')
    };

    let actions = [ previewAction, archiveAction, actAction, removeAction ];
    if (event.value.row.obj.properties.isReserved === 'true') {
      actions.push(releaseAction);
    }
    
    event.value.actions = actions;
  }

  onExecuteRowAction(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      const nodeRefBase = args.row.obj.nodeRef;
      const nodeRef = nodeRefBase.substring(nodeRefBase.lastIndexOf("/") + 1);
      this.router.navigateByUrl('/shared/(viewer:view/' + nodeRef + ')');
    } else if (args.action.id === 1) {
      this.archiveService.reservePendingDocument(args.row.obj.nodeRef, this.loggedInUserName);
      this.router.navigate(['/archive/file'], { queryParams: { nodeRef: args.row.obj.nodeRef }});
    }else if (args.action.id === 2) {
      this.router.navigate(['/archive/act'], { queryParams: { nodeRef: args.row.obj.nodeRef }});
    }else if (args.action.id === 3){
      this.openDeleteDialog(args.row.obj.nodeRef);
    }else if(args.action.id === 4){
      this.releaseDocument(args.row.obj.nodeRef, this.loggedInUserName);
    }
  }

  openDeleteDialog(element): void {
    const dialogRef = this.dialog.open(DeletePendingDocumentDialogComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(res => {      
      if (res)
        this.removeDocument(element, res.reason);
    });
  }

  removeDocument = (nodeRef, reason) => {
    this.archiveService.removePendingDocument(nodeRef, reason).then(
      _ => {
        this.store.dispatch(
            new SnackbarInfoAction(
                this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.REMOVED_DOCUMENT').replace('{0}', reason)));
        this.getPendingDocuments();
      }, _ => {
        this.store.dispatch(
            new SnackbarErrorAction(
                this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  releaseDocument = (nodeRef, userName) => {
    this.archiveService.releaseReservedPendingDocument(nodeRef, userName).then(
      _ => {
        this.store.dispatch(
            new SnackbarInfoAction(
                this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.ACTIONS.RELEASED_DOCUMENT').replace('{0}', userName)));
        this.getPendingDocuments();
      }, _ => {
        this.store.dispatch(
            new SnackbarErrorAction(
                this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

}
