<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE_ADMIN.MENU.CREATE_ARCHIVE_SIGNS.TITLE"></adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="create-archive-signs-main-content">
      <div class="form-row">
        <form [formGroup]="selectForm" novalidate>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVES_LIST' | translate }}</mat-label>
            <mat-select formControlName="selectedArchive" (selectionChange)="onChangeArchive($event)" >
              <mat-option *ngFor="let archive of archivesFiltered" [value]="archive.id">{{ archive.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.REGISTRIES_LIST' | translate }}</mat-label>
            <mat-select formControlName="selectedRegistry" (selectionChange)="onChangeRegistry($event)" >
              <mat-option *ngFor="let registry of registries" [value]="registry.nodeRef">{{ registry.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="section-container" *ngIf="getSelectedRegistry()">
        <div class="form-two-column">
          <div *ngIf="orgUnits && orgUnits.length > 0">
            <adf-datatable
              [rows]="orgUnits"
              [columns]="orgUnitsSchema"
              [actions]="true"
              (showRowActionsMenu)="onShowRowActionsMenuOrgUnits($event)"
              (executeRowAction)="onExecuteRowActionOrgUnits($event)"
              (row-select)="onRowOrgUnitsSelect($event)"
              (row-unselect)="onRowOrgUnitsUnselect($event)"
              >
            </adf-datatable>
          </div>
          <span *ngIf="!orgUnits || !orgUnits.length">{{ 'APP.ARCHIVE_ADMIN.NO_ORG_UNITS' | translate }}</span>
        </div>
        <div class="form-column">
          <form [formGroup]="orgUnitForm" novalidate>
            <mat-form-field appearance="standard" >
              <mat-label>{{ 'APP.ARCHIVE_ADMIN.ORG_UNIT_INDEX' | translate }}</mat-label>
              <input matInput formControlName="orgIndex" name="orgIndex" type="text" maxlength="2" [readonly]="isEditingOrgUnit" />
              <mat-hint>{{ 'APP.ARCHIVE_ADMIN.INDEX_HINT' | translate }}...</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.ARCHIVE_ADMIN.ORG_UNIT_TITLE' | translate }}</mat-label>
              <input matInput formControlName="title" name="title" type="text" />
              <mat-hint>{{ 'APP.ARCHIVE_ADMIN.ORG_UNIT_HINT' | translate }}...</mat-hint>
            </mat-form-field>
          </form>
          <div class="form-footer">
            <button *ngIf="!isEditingOrgUnit" mat-raised-button (click)="createOrgUnit()">{{ 'APP.ARCHIVE.CREATE' | translate }}</button>
            <button *ngIf="isEditingOrgUnit" mat-raised-button (click)="updateOrgUnit()">{{ 'APP.ARCHIVE.SAVE' | translate }}</button>
            <button *ngIf="isEditingOrgUnit" mat-raised-button (click)="cancelEditOrgUnit()">{{ 'APP.ARCHIVE.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="section-container" *ngIf="selectedOrgUnit">
        <div class="form-two-column">
          <div *ngIf="archSigns && archSigns.length > 0">
            <adf-datatable
              [rows]="archSigns"
              [columns]="archSignsSchema"
              [actions]="true"
              (showRowActionsMenu)="onShowRowActionsMenuArchSigns($event)"
              (executeRowAction)="onExecuteRowActionArchSigns($event)">
            </adf-datatable>
          </div>
          <span *ngIf="!archSigns || !archSigns.length">{{ 'APP.ARCHIVE_ADMIN.NO_ARCH_SIGNS' | translate }}</span>
        </div>
        <div class="form-column">
          <form [formGroup]="archiveSignForm" novalidate>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVE_SIGN_INDEX' | translate }}</mat-label>
              <input matInput formControlName="asIndex" name="asIndex" type="text" maxlength="4" [readonly]="isEditingArchSign" />
              <mat-hint>{{ 'APP.ARCHIVE_ADMIN.AS_INDEX_HINT' | translate }}...</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVE_SIGN_TITLE' | translate }}</mat-label>
              <input matInput formControlName="asTitle" name="asTitle" type="text" />
              <mat-hint>{{ 'APP.ARCHIVE_ADMIN.AS_TITLE_HINT' | translate }}...</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVE_SIGN_MONTHS' | translate }}</mat-label>
              <input matInput formControlName="months" name="months" type="number" [readonly]="isEditingArchSign" />
              <mat-hint>{{ 'APP.ARCHIVE_ADMIN.AS_MONTHS_HINT' | translate }}...</mat-hint>
            </mat-form-field>
          </form>
          <div class="form-footer">
            <button *ngIf="!isEditingArchSign" mat-raised-button (click)="createArchSign()">{{ 'APP.ARCHIVE.CREATE' | translate }}</button>
            <button *ngIf="isEditingArchSign" mat-raised-button (click)="updateArchSign()">{{ 'APP.ARCHIVE.SAVE' | translate }}</button>
            <button *ngIf="isEditingArchSign" mat-raised-button (click)="cancelEditArchSign()">{{ 'APP.ARCHIVE.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
