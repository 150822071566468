import { Component, Inject, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { TagService } from '@alfresco/adf-content-services';
import { Tag } from '@alfresco/js-api';
import { Subject } from 'rxjs';

import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, FormsModule, MatDialogModule, MatInputModule, MatButtonModule],
  selector: 'adf-tags-dialog',
  templateUrl: './edit-tags.dialog.html',
  styleUrls: ['./edit-tags.dialog.scss'],
  host: { class: 'adf-tags-dialog' },
  encapsulation: ViewEncapsulation.None
})
export class TagsDialogComponent implements OnInit, OnDestroy {
  fileName: string;
  newTag: string = '';
  tags: Tag[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TagsDialogComponent>,
    private tagService: TagService,
    private translateService: TranslateService,
    private store: Store<any>,
  ) { }

  ngOnInit() {
    if (this.data.node?.entry) {
      this.fileName = this.data.node.entry.name;
      this.getTags(this.data.node.entry.id);
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getTags(nodeId: string) {
    this.tagService.getTagsByNodeId(nodeId).subscribe(
      (tagPaging) => {
        if (tagPaging.list && tagPaging.list.entries) {
          this.tags = tagPaging.list.entries.map((entry) => entry.entry);
        } else {
          console.error('Invalid tag response:', tagPaging);
        }
      },
      (error) => {
        console.error('Error fetching tags:', error);
      }
    );
  }

  addTag() {
    if (this.newTag.trim().length > 50) {
      this.translateService
        .get("TAGS.MAXIMUM-LENGTH")
        .subscribe((translation: string) => {
          this.showSnackBarError(translation);
        });
      return;
    }

    if (this.tags.length >= 20) {
      this.translateService
        .get("TAGS.MAXIMUM-NUMBER")
        .subscribe((translation: string) => {
          this.showSnackBarError(translation);
        });
      return;
    }

    if (this.data.node && this.data.node.entry && this.newTag.trim() !== '') {
      const tagToAdd = this.newTag.trim();
      this.tagService.addTag(this.data.node.entry.id, tagToAdd).subscribe(
        () => {
          this.getTags(this.data.node.entry.id);
          this.newTag = '';
        },
        (error) => {
          console.error('Error adding tag:', error);
        }
      );
    }
  }

  private showSnackBarError(message: string) {
    const action = new SnackbarErrorAction(message);
    this.store.dispatch(action);
  }

  removeTag(tag: Tag) {
    this.tagService.removeTag(this.data.node.entry.id, tag.id).subscribe(
      () => {
        this.tags = this.tags.filter(t => t.id !== tag.id);
      },
      (error) => {
        console.error('Error removing tag:', error);
      }
    );
  }
}
