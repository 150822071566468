import { Constants, ContentApiService, ArchiveService, PageLayoutComponent, PageLayoutHeaderComponent, PageLayoutContentComponent } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NodeActionsService } from '../../../services/node-actions.service';
import { SubjectInfoComponent } from './subject-info/subject-info.component';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    MatIconModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-archive-file',
  templateUrl: './archive-file.component.html',
  styleUrls: ['./archive-file.component.scss']
})
export class ArchiveFileComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  cache: Map<string, any[]> = new Map();
  subjectsAtIndex: Map<number, any[]> = new Map();

  registries: any[] = [];
  organizationalUnits: any[] = [];
  archiveSigns: any[] = [];
  today = new Date();

  documentNodeRef: string = null;

  archiveFileForm = new FormGroup({
    selectedRegistry: new FormControl(undefined, Validators.required),
    selectedOrganizationalUnit: new FormControl(undefined, Validators.required),
    selectedArchiveSign: new FormControl(undefined, Validators.required),
    subjectIndex: new FormControl(undefined, [Validators.min(1), this.validateIndex()]),
    sequentialIndex: new FormControl(undefined, Validators.min(1)),
    dateArchiving: new FormControl(this.today),
    dateSent: new FormControl(),
    subjectName: new FormControl(''),
    senderName: new FormControl(''),
    senderNumber: new FormControl(''),
    notes: new FormControl('')
  });

  constructor(
    private archiveService: ArchiveService,
    private contentApiService: ContentApiService,
    private nodeActionsService: NodeActionsService,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  get selectedRegistry() {
    return this.archiveFileForm.get('selectedRegistry');
  }

  get selectedOrganizationalUnit() {
    return this.archiveFileForm.get('selectedOrganizationalUnit');
  }

  get selectedArchiveSign() {
    return this.archiveFileForm.get('selectedArchiveSign');
  }

  get subjectIndex() {
    return this.archiveFileForm.get('subjectIndex');
  }

  get sequentialIndex() {
    return this.archiveFileForm.get('sequentialIndex');
  }

  get selectedRegistryCounter(): number {
    const selectedRegistry = this.registries.find((registry) => registry['nodeRef'] === this.selectedRegistry.value);

    return selectedRegistry?.['counter'] ?? 0;
  }

  async ngOnInit(): Promise<void> {
    this.registries = await this.archiveService.getRegistryBooks();

    this.selectedRegistry.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateOrganizationalUnits());

    this.selectedOrganizationalUnit.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateArchiveSigns());

    this.subjectIndex.valueChanges
      .pipe(debounceTime(Constants.CONTINUED_TYPING_THRESHOLD_VALUE), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((value) => value && this.checkForSubjectsAtIndex());
  }

  openSubjectsAtIndex(): void {
    this.dialog.open(SubjectInfoComponent, { data: this.subjectsAtIndex.get(this.subjectIndex.value) });
  }

  async preArchivalChecks(): Promise<void> {
    const requestBody = { ...this.archiveFileForm.value };
    const existingSubjects = await this.archiveService.getSubjectsSenderNumber(requestBody.selectedRegistry, requestBody.senderNumber);
    if (existingSubjects && existingSubjects.length > 0) {
      const duplicatesString = existingSubjects.map(el => el['title']).join(', ');
      const shouldContinue = await this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.ARCHIVE.ARCHIVE_FILE_DUPLICATE_SUBJECTS.TITLE',
          message: this.translation.instant('APP.ARCHIVE.ARCHIVE_FILE_DUPLICATE_SUBJECTS.CONTENT').replace('{0}', duplicatesString),
          yesLabel: 'APP.GENERAL.YES',
          noLabel: 'APP.GENERAL.CANCEL'
        },
        minWidth: '250px',
        maxWidth: '450px'
      }).afterClosed().toPromise();
      if (!shouldContinue) throw new Error('aborted');
    }
  }

  async archiveFile(): Promise<void> {
    if (this.archiveFileForm.invalid) return;

    try {
      await this.preArchivalChecks();
    } catch (_) {
      return;
    }

    const requestBody = { ...this.archiveFileForm.value };

    try {
      const nodeRef = this.route.snapshot.queryParams.nodeId ? `workspace://SpacesStore/${this.route.snapshot.queryParams.nodeId}` : '';
      const result = await this.archiveService.archiveSubject(
        nodeRef,
        requestBody.selectedRegistry,
        requestBody.selectedOrganizationalUnit,
        requestBody.selectedArchiveSign,
        requestBody.subjectIndex,
        requestBody.sequentialIndex,
        requestBody.subjectName,
        requestBody.dateArchiving,
        requestBody.senderName,
        requestBody.senderNumber,
        requestBody.dateSent,
        requestBody.notes
      );

      const nodeGuid = result.nodeRef.substring(result.nodeRef.lastIndexOf('/') + 1);
      const node = await this.contentApiService.getNodeInfo(nodeGuid).toPromise();
      const nodeParentId = this.nodeActionsService.getEntryParentId(node);
      const url = `/libraries/${nodeParentId}/(viewer:view/${nodeGuid})`;

      // maybe use in future, in case redirection is made to be optional
      //this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE.SUCCESS.ARCHIVE_FILE')));

      this.router.navigateByUrl(url);
    } catch (error) {
      console.log({ error });
      this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  private async updateOrganizationalUnits(): Promise<void> {
    this.subjectIndex.setValue(undefined, { emitEvent: false });
    this.selectedArchiveSign.setValue(undefined, { emitEvent: false });
    this.selectedOrganizationalUnit.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedRegistry.value;

    if (this.cache.has(nodeRef)) {
      this.organizationalUnits = this.cache.get(nodeRef);
    } else {
      this.organizationalUnits = await this.archiveService.getOrganizationalUnits(nodeRef);
      this.cache.set(nodeRef, this.organizationalUnits);
    }
  }

  private async updateArchiveSigns(): Promise<void> {
    this.selectedArchiveSign.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedOrganizationalUnit.value;

    if (this.cache.has(nodeRef)) {
      this.archiveSigns = this.cache.get(nodeRef);
    } else {
      this.archiveSigns = await this.archiveService.getArchiveSigns(nodeRef);
      this.cache.set(nodeRef, this.archiveSigns);
    }
  }

  private async checkForSubjectsAtIndex(): Promise<void> {
    if (this.subjectIndex.invalid) return;

    if (this.subjectsAtIndex.has(this.subjectIndex.value)) return;

    const subjects = await this.archiveService.getSubjectAtIndex(this.selectedRegistry.value, this.subjectIndex.value);
    if (subjects.length) {
      this.subjectsAtIndex.set(this.subjectIndex.value, subjects);
    }
  }

  private validateIndex(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const selectedRegistry = this.registries.find((registry) => registry['nodeRef'] === this.selectedRegistry.value);

      if (!selectedRegistry) return null;

      return selectedRegistry['invActs'].split(',').includes(control.value?.toString()) ? { invalidIndex: { value: control.value } } : null;
    };
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
