import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { Person } from '@alfresco/js-api';
import { TranslationService } from '@alfresco/adf-core';
import { UserService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule
  ],
  selector: 'aca-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateUserDialogComponent implements OnInit {

  form: FormGroup;
  
  editTitle = 'APP.SETTINGS.EDIT_USER';
  createTitle = 'APP.SETTINGS.NEW_USER';

  hasProcessedEntity: Boolean = false;

  fcUsername: FormControl;
  fcFirstName: FormControl;
  fcLastName: FormControl;
  fcEmail: FormControl;
  fcPassword: FormControl;
  fcPasswordRepeated: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialogRef<CreateUserDialogComponent>,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private userService: UserService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
  }

  get editing(): boolean {
    return Boolean(this.data);
  }

  ngOnInit() {
    let username = this.data ? this.data.id : '';
    let first_name = this.data ? this.data.firstName : '';
    let last_name = this.data ? this.data.lastName : '';
    let email = this.data ? this.data.email : '';

    const validators = {
      username: [
        Validators.required,
      ],
      first_name: [
        Validators.required,
      ],
      email: [
        Validators.required,
        Validators.email,
      ],
      password: !this.data ? [
        Validators.required,
      ] : [],
      password_repeated: !this.data ? [
        Validators.required,
      ] : [],
    };

    this.form = this.formBuilder.group({
      username: [ username, validators.username ],
      first_name: [ first_name, validators.first_name ],
      last_name: [ last_name ],
      email: [ email, validators.email ],
      password: [ '', validators.password ],
      password_repeated: [ '', validators.password ],
    });

    this.fcUsername = this.form.controls['username'] as FormControl;
    this.fcFirstName = this.form.controls['first_name'] as FormControl;
    this.fcLastName = this.form.controls['last_name'] as FormControl;
    this.fcEmail = this.form.controls['email'] as FormControl;
    this.fcPassword = this.form.controls['password'] as FormControl;
    this.fcPasswordRepeated = this.form.controls['password_repeated'] as FormControl;
  }

  get username(): string {
    const { username } = this.form.value;

    return (username || '').trim();
  }

  get first_name(): string {
    const { first_name } = this.form.value;

    return (first_name || '').trim();
  }

  get last_name(): string {
    const { last_name } = this.form.value;

    return (last_name || '').trim();
  }

  get email(): string {
    const { email } = this.form.value;

    return (email || '').trim();
  }

  get password(): string {
    const { password } = this.form.value;

    return (password || '').trim();
  }

  get password_repeated(): string {
    const { password_repeated } = this.form.value;

    return (password_repeated || '').trim();
  }

  private create(): Promise<Person> {
    return this.userService.createUser(this.username, this.password, this.first_name, this.last_name, this.email);
  }

  private edit(): Promise<Person> {
    return this.userService.updateUser(this.username, this.first_name, this.last_name, this.email, null, this.password);
  }

  closeDialog() {
    this.dialog.close(this.hasProcessedEntity);
  }

  submit(close=true) {
    const { form, editing } = this;

    if (!form.valid) { return; }

    (editing ? this.edit() : this.create())
      .then(_ => {
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant(this.editing ? 'APP.SETTINGS.USER_UPDATED' : 'APP.SETTINGS.USER_CREATED')
        ));
        this.hasProcessedEntity = true;
        if (editing || close)
          this.closeDialog();
        else if (!editing && !close)
          form.reset();
      })
      .catch(error => this.handleError(error));
  }

  handleError(error: any): any {
    this.store.dispatch(new SnackbarErrorAction(
      this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));

    return error;
  }
}