<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.LLEIDA.VIEW_PROCESSES.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="processes-main-content">
      <div class="pmc-header">
        <div class="pmc-header-row1">
          <mat-button-toggle-group [(ngModel)]="inProcess" (change)="onFormChange($event)">
            <mat-button-toggle value="inProcess">{{ 'APP.LLEIDA.GENERAL.IN_PROCESS' | translate }}</mat-button-toggle>
            <mat-button-toggle value="signed">{{ 'APP.LLEIDA.GENERAL.SIGNED' | translate }}</mat-button-toggle>
            <mat-button-toggle value="error">{{ 'APP.LLEIDA.GENERAL.ERROR' | translate }}</mat-button-toggle>
            <mat-button-toggle value="cancelled">{{ 'APP.LLEIDA.GENERAL.CANCELLED' | translate }}</mat-button-toggle>
          </mat-button-toggle-group>

          <span class="pmc-header-row1-right">
            <button mat-button (click)="onSortByDate($event)">
              {{ currentSort === 'date' ? '•' : '' }}
              {{ 'APP.LLEIDA.GENERAL.DATE_STARTED' | translate }}
              <mat-icon>{{ sortDateAsc ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
            </button>

            <button mat-raised-button (click)="onToggleShowFilters($event)">
              <mat-icon>filter_list</mat-icon>
            </button>
          </span>
        </div>
        <div class="pmc-header-row2" [hidden]="!showFilters" (change)="onFilterFormChange($event)">
          <form [formGroup]="filterForm" (keydown.enter)="onFormEnter($event)" novalidate>
            <mat-form-field appearance="standard" id="mff-email">
              <mat-label>{{ 'APP.LLEIDA.GENERAL.EMAIL' | translate }}</mat-label>
              <input matInput formControlName="email" name="email" type="text" />
            </mat-form-field>
            <button mat-button (click)="onResetFilters($event)">
              <mat-icon>clear_all</mat-icon>
            </button>
          </form>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="pmc-body">
        <mat-spinner *ngIf="isLoading" [diameter]="60" style="margin: 0 auto"></mat-spinner>
        <div *ngIf="!isLoading">
          <div *ngIf="processes.length === 0">
            {{ 'APP.LLEIDA.PROCESS_LIST.NO_ITEMS' | translate }}
          </div>

          <div *ngIf="processes.length > 0">
            <div class="pmcb-list">
              <div
                *ngFor="let signature of getVisibleProcesses(); let i = index"
                class="pmcbl-process"
                [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
              >
                <img [src]="getProcessIconURL()" alt="process icon" (click)="onClickProcess(signature.signature_id)" />
                <div class="pmcblt-data">
                  <div class="pmcbltd-row">{{ signature.config_name }}</div>
                  <ng-container *ngFor="let signatory of signature.signatories">
                    <div class="pmcbltd-row">
                      <span class="pmcbltdr-pair">
                        <span class="pmcbltdrp-label">Full Name: </span>
                        <span class="pmcbltdrp-value">{{ signatory.name }} {{ signatory.surname }}</span>
                      </span>
                      <span class="pmcbltdr-pair">
                        <span class="pmcbltdrp-label">E-mail: </span>
                        <span class="pmcbltdrp-value">{{ signatory.email }}</span>
                      </span>
                    </div>
                    <div class="pmcbltd-row">
                      <span class="pmcbltdr-pair">
                        <span class="pmcbltdrp-label">Start Date: </span>
                        <span class="pmcbltdrp-value">{{ convertFromUnixTimestamp(signature.signature_start_date) }}</span>
                      </span>
                      <span class="pmcbltdr-pair">
                        <span class="pmcbltdrp-label">Update Date: </span>
                        <span class="pmcbltdrp-value">{{ convertFromUnixTimestamp(signature.signature_status_date) }}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="pmcb-pagination">
              <div class="pmcbp-inner-container">
                <button mat-button [disabled]="currentPage <= 1" (click)="onPreviousPage()">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-form-field appearance="standard" id="pagination-page-input">
                  <input matInput [(ngModel)]="selectedPage" (change)="onJumpToPage()" />
                </mat-form-field>
                <span class="pmcbp-pages-larger-font">/</span>
                <span class="pmcbp-total-pages pmcbp-pages-larger-font">{{ numberOfPages }}</span>
                <button mat-button [disabled]="currentPage >= numberOfPages" (click)="onNextPage()">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
