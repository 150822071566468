<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.PROFILE_SETTINGS.PAGE_TITLE"></adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>
  <aca-page-layout-content>
      <div class="form-container">
        <form [formGroup]="personalForm" (submit)="onPersonalFormSubmit()">
          <h4>{{ 'APP.PROFILE_SETTINGS.UPDATE_PERSONAL_INFO' | translate }}</h4>
          <mat-form-field class="adf-full-width">
            <input
              id="user-username-input"
              placeholder="{{ 'APP.SETTINGS.USERNAME' | translate }}"
              matInput
              required
              readonly="true"
              [formControl]="fcUsername" />
          </mat-form-field>

          <mat-form-field class="adf-full-width">
            <input
              id="user-first-name-input"
              matInput
              placeholder="{{ 'APP.SETTINGS.FIRST_NAME' | translate }}"
              required
              [formControl]="fcFirstName"/>

            <mat-hint *ngIf="fcFirstName.dirty">
              <span *ngIf="fcFirstName.errors?.required">
                {{ 'APP.SETTINGS.FIRST_NAME_REQUIRED' | translate }}
              </span>
              <span *ngIf="!fcFirstName.errors?.required && fcFirstName.errors?.message">
                {{ fcFirstName.errors?.message | translate }}
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="adf-full-width">
            <input
              id="user-last-name-input"
              matInput
              placeholder="{{ 'APP.SETTINGS.LAST_NAME' | translate }}"
              [formControl]="fcLastName"/>
          </mat-form-field>

          <mat-form-field class="adf-full-width">
            <input
              id="user-email-input"
              matInput
              placeholder="{{ 'APP.SETTINGS.EMAIL' | translate }}"
              required
              [formControl]="fcEmail"/>

            <mat-hint *ngIf="fcEmail.dirty">
              <span *ngIf="fcEmail.errors?.required">
                {{ 'APP.SETTINGS.EMAIL_REQUIRED' | translate }}
              </span>
              <span *ngIf="!fcEmail.errors?.required && fcEmail.errors?.message">
                {{ fcEmail.errors?.message | translate }}
              </span>
            </mat-hint>
          </mat-form-field>

          <div class="form-button-container">
            <button
              mat-button
              mat-raised-button
              class="custom-button"
              type="submit"
              [disabled]="!personalForm.valid">
              {{ 'APP.ACTIONS.UPDATE' | translate }}
            </button>
          </div>
        </form>

        <form [formGroup]="passwordForm" (submit)="onPasswordFormSubmit()">
          <h4>{{ 'APP.PROFILE_SETTINGS.CHANGE_PASSWORD' | translate }}</h4>
          <mat-form-field class="adf-full-width">
            <input
              id="user-old-password-input"
              type="password"
              matInput
              placeholder="{{ 'APP.PROFILE_SETTINGS.OLD_PASSWORD' | translate }}"
              required
              [formControl]="fcOldPassword"/>

            <mat-hint *ngIf="fcOldPassword.dirty">
              <span *ngIf="fcOldPassword.errors?.required">
                {{ 'APP.PROFILE_SETTINGS.OLD_PASSWORD_REQUIRED' | translate }}
              </span>
              <span *ngIf="!fcOldPassword.errors?.required && fcOldPassword.errors?.message">
                {{ fcOldPassword.errors?.message | translate }}
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="adf-full-width">
            <input
              id="user-password-input"
              type="password"
              matInput
              placeholder="{{ 'APP.SETTINGS.PASSWORD' | translate }}"
              required
              [formControl]="fcPassword"/>

            <mat-hint *ngIf="fcPassword.dirty">
              <span *ngIf="fcPassword.errors?.required">
                {{ 'APP.SETTINGS.PASSWORD_REQUIRED' | translate }}
              </span>
              <span *ngIf="!fcPassword.errors?.required && fcPassword.errors?.message">
                {{ fcPassword.errors?.message | translate }}
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="adf-full-width">
            <input
              id="user-password-repeat-input"
              type="password"
              matInput
              placeholder="{{ 'APP.SETTINGS.PASSWORD_REPEATED' | translate }}"
              required
              [formControl]="fcPasswordRepeated"/>

            <mat-hint *ngIf="fcPasswordRepeated.dirty">
              <span *ngIf="fcPasswordRepeated.errors?.required">
                {{ 'APP.SETTINGS.PASSWORD_REPEATED_REQUIRED' | translate }}
              </span>
              <span *ngIf="fcPasswordRepeated.value !== fcPassword.value">
                {{ 'APP.SETTINGS.PASSWORD_REPEATED_NOT_MATCH' | translate }}
              </span>
              <span *ngIf="!fcPasswordRepeated.errors?.required && !fcPasswordRepeated.errors?.not_match && fcPasswordRepeated.errors?.message">
                {{ fcPasswordRepeated.errors?.message | translate }}
              </span>
            </mat-hint>
          </mat-form-field>

          <div class="form-button-container">
            <button
              mat-button
              mat-raised-button
              class="custom-button"
              type="submit"
              [disabled]="!passwordForm.valid">
              {{ 'APP.ACTIONS.UPDATE' | translate }}
            </button>
          </div>
        </form>

        <div class="change-avatar-container">
          <h4>{{ 'APP.PROFILE_SETTINGS.CHANGE_AVATAR' | translate }}</h4>
          <img
            src="#"
            class="avatar-image-component"
            alt="avatar-preview"
            [hidden]="!avatarDataReady"
            #avatarPreviewComp />

          <input
            hidden
            (change)="onAvatarFileSelected($event)"
            type="file"
            accept=".jpg,.jpeg,.gif,.png,.tiff"
            id="user-avatar-input"
            #avatarInput />

          <div class="form-button-container">
            <div class="float-left-container">
              <button
                type="button"
                mat-button
                mat-raised-button
                (click)="avatarInput.click()">
                {{ 'APP.PROFILE_SETTINGS.SELECT_IMAGE' | translate }}
              </button>
              
              <button
                *ngIf="selectedAvatarFile"
                type="button"
                mat-button
                mat-raised-button
                (click)="onClearAvatarFile()">
                {{ 'APP.PROFILE_SETTINGS.CLEAR_IMAGE' | translate }}
              </button>
            </div>

            <div class="float-right-container">
              <button
                *ngIf="existingAvatarUrl"
                type="button"
                mat-button
                mat-raised-button
                (click)="onUseDefaultAvatarFile()">
                {{ 'APP.PROFILE_SETTINGS.USE_DEFAULT_IMAGE' | translate }}
              </button>

              <button
                type="button"
                mat-button
                mat-raised-button
                class="custom-button"
                (click)="onUpdateAvatar()"
                [disabled]="!selectedAvatarFile">
                {{ 'APP.ACTIONS.UPDATE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
  </aca-page-layout-content>
</aca-page-layout>