import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { TranslationService } from '@alfresco/adf-core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ArchiveService, ContentApiService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent } from '@alfresco/aca-shared';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { NodeActionsService } from '../../../services/node-actions.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    MatAutocompleteModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-archive-act',
  templateUrl: './archive-act.component.html',
  styleUrls: ['./archive-act.component.scss']
})
export class ArchiveActComponent implements OnInit, OnDestroy {
  private invoicePattern = '';
  onDestroy$ = new Subject<boolean>();

  cache: Map<string, any[]> = new Map();
  registries: any[] = [];
  komitenti: any[] = [];
  hasKomitenti: boolean = false;
  komitentiFiltered: Observable<any[]>;
  inventoriesOfActs: any[] = [];
  additionalProperties = [
    {
      name: this.translation.instant('APP.ARCHIVE.NO_ADDITIONAL_PROPERTIES'),
      value: null
    },
    {
      name: this.translation.instant('APP.ARCHIVE.INVOICE'),
      value: 'invoice'
    }
  ];
  currencies: any[];
  today = new Date();

  archiveActForm = new FormGroup({
    selectedRegistry: new FormControl(undefined, Validators.required),
    selectedInventoryOfActs: new FormControl(undefined, Validators.required),
    dateArchiving: new FormControl(this.today),
    senderName: new FormControl('', this.requiredIfNotKomitenti()),
    senderValue: new FormControl('', this.requiredIfKomitenti()),
    senderNumber: new FormControl('', this.requiredIfInvoice()),
    dateSent: new FormControl(undefined, this.requiredIfInvoice()),
    divorceDate: new FormControl(),
    divorceSign: new FormControl(''),
    notes: new FormControl(''),
    selectedAdditionalProperties: new FormControl(null),
    invoiceAmount: new FormControl(undefined, Validators.min(0)),
    selectedCurrency: new FormControl()
  });

  constructor(
    private archiveService: ArchiveService,
    private translation: TranslationService,
    private store: Store<AppStore>,
    private contentApiService: ContentApiService,
    private nodeActionsService: NodeActionsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  get selectedRegistry() {
    return this.archiveActForm.get('selectedRegistry');
  }

  get selectedInventoryOfActs() {
    return this.archiveActForm.get('selectedInventoryOfActs');
  }

  get invoiceAmount() {
    return this.archiveActForm.get('invoiceAmount');
  }

  get senderName() {
    return this.archiveActForm.get('senderName');
  }

  get senderValue() {
    return this.archiveActForm.get('senderValue');
  }

  get senderNumber() {
    return this.archiveActForm.get('senderNumber');
  }

  get dateSent() {
    return this.archiveActForm.get('dateSent');
  }

  get isInvoice() {
    return this.archiveActForm?.get('selectedAdditionalProperties').value === 'invoice';
  }

  async ngOnInit(): Promise<void> {
    this.registries = await this.archiveService.getRegistryBooks();
    
    const result = await this.archiveService.getArchiveGlobalConfiguration();

    this.loadKomitenti();
    
    this.invoicePattern = result['invoice'];
    this.currencies = result['currency'].split(',');
    this.archiveActForm.get('selectedCurrency').setValue(this.currencies[0]);

    this.selectedRegistry.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateInventoryOfActs());
  }

  async preArchivalChecks(): Promise<void> {
    if (!this.isInvoice && this.isInventoryUsedForInvoice()) {
      const shouldContinue = await this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.ARCHIVE.ARCHIVE_ACT_INVOICE_WARNING.TITLE',
          message: this.translation.instant('APP.ARCHIVE.ARCHIVE_ACT_INVOICE_WARNING.CONTENT'),
          yesLabel: 'APP.GENERAL.YES',
          noLabel: 'APP.GENERAL.CANCEL'
        },
        minWidth: '250px',
        maxWidth: '450px'
      }).afterClosed().toPromise();
      if (!shouldContinue) throw new Error('aborted');
    }

    const requestBody = { ...this.archiveActForm.value };
    const existingSubjects = await this.archiveService.getActsSenderNumber(requestBody.selectedInventoryOfActs, requestBody.senderNumber);
    if (existingSubjects && existingSubjects.length > 0) {
      const duplicatesString = existingSubjects.map(el => el['title']).join(', ');
      const shouldContinue = await this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.ARCHIVE.ARCHIVE_FILE_DUPLICATE_ACTS.TITLE',
          message: this.translation.instant('APP.ARCHIVE.ARCHIVE_FILE_DUPLICATE_ACTS.CONTENT').replace('{0}', duplicatesString),
          yesLabel: 'APP.GENERAL.YES',
          noLabel: 'APP.GENERAL.CANCEL'
        },
        minWidth: '250px',
        maxWidth: '450px'
      }).afterClosed().toPromise();
      if (!shouldContinue) throw new Error('aborted');
    }
  }

  async archiveAct(): Promise<void> {
    if (this.archiveActForm.invalid) return;

    try {
      await this.preArchivalChecks();
    } catch (_) {
      return;
    }

    const requestBody = { ...this.archiveActForm.value };

    let sender: string = '', senderId: string = '';
    if (this.hasKomitenti) {
      const selectedKomitent = this.komitenti.find(komitent => komitent['id'] === requestBody.senderValue);
      sender = selectedKomitent['name'];
      senderId = selectedKomitent['id'];
    } else {
      sender = requestBody.senderName;
    }

    try {
      const nodeRef = this.route.snapshot.queryParams.nodeId ? `workspace://SpacesStore/${this.route.snapshot.queryParams.nodeId}` : '';
      const result = await this.archiveService.archiveInventoryAct(
        nodeRef,
        requestBody.selectedRegistry,
        requestBody.selectedInventoryOfActs,
        requestBody.dateArchiving,
        sender,
        senderId,
        requestBody.senderNumber,
        requestBody.dateSent,
        requestBody.divorceDate,
        requestBody.divorceSign,
        requestBody.notes,
        this.isInvoice,
        requestBody.invoiceAmount,
        requestBody.selectedCurrency
      );

      const nodeGuid = result.nodeRef.substring(result.nodeRef.lastIndexOf('/') + 1);
      const node = await this.contentApiService.getNodeInfo(nodeGuid).toPromise();
      const nodeParentId = this.nodeActionsService.getEntryParentId(node);
      const url = `/libraries/${nodeParentId}/(viewer:view/${nodeGuid})`;

      // maybe use in future, in case redirection is made to be optional
      // this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE.SUCCESS.ARCHIVE_ACT')));

      this.router.navigateByUrl(url);
    } catch (error) {
      this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  private async updateInventoryOfActs(): Promise<void> {
    this.selectedInventoryOfActs.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedRegistry.value;

    if (this.cache.has(nodeRef)) {
      this.inventoriesOfActs = this.cache.get(nodeRef);
    } else {
      this.inventoriesOfActs = await this.archiveService.getInventoryOfActs(nodeRef);
      this.cache.set(nodeRef, this.inventoriesOfActs);
    }
  }

  private isInventoryUsedForInvoice(): boolean {
    const inventoryOfActs = this.inventoriesOfActs.find((inventory) => inventory['nodeRef'] === this.selectedInventoryOfActs.value);
    return this.invoicePattern.split(',').some((pattern) => inventoryOfActs['name'].toLowerCase().includes(pattern));
  }

  private requiredIfInvoice(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      return this.isInvoice && !control.value ? { required: false } : null;
    };
  }

  private requiredIfKomitenti(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      return this.hasKomitenti && !control.value ? { required: false } : null;
    };
  }

  private requiredIfNotKomitenti(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      return !this.hasKomitenti && !control.value ? { required: false } : null;
    };
  }

  private async loadKomitenti() {
    const result = await this.archiveService.getKomitenti();
    this.komitenti = result.komitenti;
    this.hasKomitenti = this.komitenti.length > 0;
    this.komitentiFiltered = this.archiveActForm.get('senderValue').valueChanges.pipe(
      startWith(''),
      map(value => this._filterKomitenti(value || '')),
    );
  }

  private _filterKomitenti(value: string): any[] {
    if (this.komitenti.length === 0)
      return [];
    const filterValue = value.toLowerCase();
    return this.komitenti.filter(komitent => komitent['name'].toLowerCase().includes(filterValue) || komitent['id'].toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
