import { TagService } from '@alfresco/adf-content-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LleidaService {
  constructor(
    private http: HttpClient,
    private tagService: TagService
  ) { }

  async getSignatureList(
    user: string,
    password: string,
    startDate: number,
    endDate: number,
    statusFilter: string
  ): Promise<any> {
    const url = 'https://api.lleida.net/cs/v1/get_signature_list';
    const requestBody = {
      request: 'GET_SIGNATURE_LIST',
      user: user,
      password: password,
      start_date: startDate,
      end_date: endDate,
      status: statusFilter
    };

    try {
      return this.http.post(url, requestBody).toPromise();
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async getSignatureTypeDefinitions(
    user: string,
    password: string
  ): Promise<any> {
    const url = 'https://api.lleida.net/cs/v1/get_config_list';
    const requestBody = {
      request: 'GET_CONFIG_LIST',
      user: user,
      password: password
    };

    try {
      const response: any = await this.http.post(url, requestBody).toPromise();

      const transformedData = response.config.map(item => ({
        id: item.config_id,
        title: item.name
      }));

      return transformedData;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async startSignature(
    user: string,
    password: string,
    selectedDocument: any,
    base64Content: string,
    name: string,
    surname: string,
    email: string,
    configId: number,
    phone?: string
  ): Promise<any> {
    const url = 'https://api.lleida.net/cs/v1/start_signature';

    try {
      const requestBody = {
        request: 'START_SIGNATURE',
        request_id: '9876543210',
        user: user,
        password: password,
        signature: {
          config_id: configId,
          contract_id: 'CompanyID1233456',
          level: [
            {
              level_order: 0,
              required_signatories_to_complete_level: 1,
              signatories: [
                {
                  phone: phone || '+34666666666',
                  email: email,
                  name: name,
                  surname: surname,
                  external_id: '1'
                }
              ]
            }
          ],
          file: [
            {
              filename: selectedDocument.name,
              content: base64Content,
              file_group: 'contract_files',
              signature_position: [
                {
                  signatory_external_id: '1',
                  page: 'all',
                  x: 40.0,
                  y: 50.0,
                  height: 20,
                  width: 30,
                  rotation: 0
                },
                {
                  signatory_external_id: '1',
                  page: 'last',
                  x: 60.0,
                  y: 120.0
                }
              ]
            }
          ]
        }
      };

      console.log('Config ID in startSignature:', configId);
      return this.http.post(url, requestBody).toPromise();
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async convertUrlToBase64(url: string): Promise<string> {
    try {
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      const reader = new FileReader();
      reader.readAsDataURL(response);
      return new Promise<string>((resolve, reject) => {
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String.split(',')[1]);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // temp method to add tag when signing document
  async addLleidaTag(documentId: string) {
    const tagName = "Signed By Lleida";

    try {
      await this.tagService.addTag(documentId, tagName);

      console.log('Tag "Signed By Lleida" added successfully.');
    } catch (error) {
      console.error('Error adding or updating tag:', error);
    }
  }
}
