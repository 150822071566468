<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.CUSTOM_REPORTS.WORKFLOW_REPORT.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="workflow-report-main-content">
      <form [formGroup]="workflowReportForm" novalidate (ngSubmit)="onFormSubmit()">
        <div id="wf-main-input-container">
          <div class="wfmic-section">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.CUSTOM_REPORTS.WORKFLOW_REPORT.WORKFLOW_TYPE' | translate }}</mat-label>
              <mat-select formControlName="workflowType" name="workflowType">
                <mat-option *ngFor="let workflowType of workflowTypes" [value]="workflowType.id">{{ workflowType.title }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.CUSTOM_REPORTS.WORKFLOW_REPORT.DATE_STARTED_AFTER' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="dateStartedAfterPicker"
                [max]="today"
                formControlName="dateStartedAfter"
                name="dateStartedAfter"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="dateStartedAfterPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateStartedAfterPicker disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="wfmic-section">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.CUSTOM_REPORTS.WORKFLOW_REPORT.USER' | translate }}</mat-label>
              <input matInput formControlName="user" name="user" />
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.CUSTOM_REPORTS.WORKFLOW_REPORT.DATE_STARTED_BEFORE' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="dateStartedBeforePicker"
                [max]="today"
                formControlName="dateStartedBefore"
                name="dateStartedBefore"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="dateStartedBeforePicker"></mat-datepicker-toggle>
              <mat-datepicker #dateStartedBeforePicker disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div id="wf-footer">
          <button mat-button type="button" (click)="onFormReset($event)">{{ 'APP.ACTIONS.RESET' | translate }}</button>
          <button mat-raised-button type="submit" color="primary" (click)="resetCurrentPage()">{{ 'APP.ACTIONS.GENERATE' | translate }}</button>
          <div class="loading-container">
            <div *ngIf="isLoading">{{ 'APP.CUSTOM_REPORTS.GENERAL.LOADING' | translate }}</div>
          </div>
        </div>
        <div *ngIf="!isLoading && (showDataTable || showTaskMessage)" id="wf-table">
          <div class="table-options">
            <mat-form-field appearance="standard" class="rows-dropdown">
              <mat-label>{{ 'APP.CUSTOM_REPORTS.GENERAL.ROWS' | translate }}</mat-label>
              <mat-select formControlName="itemsPerPage" (selectionChange)="onItemsPerPageChange($event.value)">
                <mat-option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="pagination">
              <button mat-icon-button type="button" [disabled]="currentPage <= 1" (click)="onPageChange(currentPage - 1)">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <span>{{ currentPage }}</span>
              <button mat-icon-button type="button" [disabled]="currentPage >= totalPages" (click)="onPageChange(currentPage + 1)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
            <button mat-raised-button color="primary" *ngIf="showDataTable" (click)="printTable()" type="button">
              {{ 'APP.ACTIONS.PRINT' | translate }}
            </button>
          </div>
          <ng-container *ngIf="showDataTable">
            <table>
              <thead>
                <tr>
                  <th *ngFor="let column of tableColumns">{{ column.title }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of tableRows">
                  <td *ngFor="let column of tableColumns">
                    <span *ngIf="column.key !== 'workflowName' && column.key !== 'name'">{{ row[column.key] }}</span>
                    <span *ngIf="column.key === 'name'" (click)="onTaskClick(row.id)" class="link">{{ row[column.key] }}</span>
                    <span *ngIf="column.key === 'workflowName'" (click)="onWorkflowClick(row.workflowId)" class="link">{{ row[column.key] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <div *ngIf="showTaskMessage">
            {{ 'APP.CUSTOM_REPORTS.WORKFLOW_REPORT.NO_TASK_ITEMS' | translate }}
          </div>
        </div>
      </form>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
