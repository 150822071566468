<h2 mat-dialog-title>
  {{ 'APP.SERVER_SIGN.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-server-sign-document-dialog-content">
  <aca-image-annotator
    [size]="previewSize"
    [image]="thumbnail"
    [mode]="annotatorMode()"
    [contentHtml]="annotationContentHtml"
    [textScaleCoef]="6.5"
    (coordinatesChanged)="onAnnotatorCoordinatesChanged($event)"
    (coordinatesReset)="onAnnotatorCoordinatesReset()">
  </aca-image-annotator>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
      mat-button
      class="adf-dialog-action-button"
      color="primary"
      [disabled]="actionExecuting"
      (click)="signDocument()">
        {{ 'APP.SERVER_SIGN.SIGN' | translate }}
    </button>
    <button
      mat-button
      mat-dialog-close>
      {{ 'APP.GENERAL.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
