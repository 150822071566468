import { TaskStatus } from "@alfresco/aca-shared";
import { TranslationService } from "@alfresco/adf-core";

export class Utils {
  public static daysPassed(dateFrom: Date): number {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    dateFrom.setHours(0, 0, 0, 0);
    return Math.round((today.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24));
  }

  /*
    Parses a date from Alfresco format
    i.e. 2015-05-16T16:55:22:00
    Unfortunately discards timezone information, so compensation needs to be made
  */
  public static dateFromISO8601(isostr): Date {
    const parts = isostr.match(/\d+/g);
    let date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    if (isostr.toLowerCase().endsWith('z')) {
      // add to the date the timezone offset from utc (substraction is correct logic)
      date = new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    }
    return date;
  }

  public static getPriorityOptions(translation: TranslationService) {
    return [
      { id: 'all', value: translation.instant('APP.WORKFLOW.GENERAL.ALL') },
      { id: '1', value: translation.instant('APP.WORKFLOW.GENERAL.PRIORITY_HIGH') },
      { id: '2', value: translation.instant('APP.WORKFLOW.GENERAL.PRIORITY_MEDIUM') },
      { id: '3', value: translation.instant('APP.WORKFLOW.GENERAL.PRIORITY_LOW') },
    ];
  }

  public static getPriorityTranslation(translation: TranslationService, priority: string | number): string {
    switch (priority.toString()) {
      case '1':
        return translation.instant('APP.WORKFLOW.GENERAL.PRIORITY_HIGH');
      case '2':
        return translation.instant('APP.WORKFLOW.GENERAL.PRIORITY_MEDIUM');
      case '3':
        return translation.instant('APP.WORKFLOW.GENERAL.PRIORITY_LOW');
      default:
        return null;
    }
  }

  public static getTaskTypeName(taskType: string, translation: TranslationService): string {
    switch (taskType) {
      case 'wf:activitiReviewTask':
      case 'ncwf:multipleLevelReviewTask':
        return translation.instant('APP.WORKFLOW.GENERAL.TASK_TYPE.APPROVE_REJECT');
      case 'wf:approvedParallelTask':
      case 'wf:rejectedParallelTask':
      case 'ncwf:reviewGroupAdhocTask':
      case 'ncwf:endGroupAdhocTask':
      case 'ncwf:multipleLevelApprovedTask':
      case 'ncwf:multipleLevelRejectedTask':
      case 'ncwf:multipleLevelGroupAdhocTask':
      case 'ncwf:multipleAdhocTask':
      case 'ncwf:completedMultipleAdhocTask':
        return translation.instant('APP.WORKFLOW.GENERAL.TASK_TYPE.REVIEW');
      default:
        return null;
    }
  }

  public static getWorkflowTypeName(workflowType: string, translation: TranslationService): string {
    switch (workflowType) {
      case 'activiti$groupApproveRejectDoc':
        return translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.GARD');
      case 'activiti$groupReceiveDoc':
        return translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.GRD');
      case 'activiti$multipleLevelApproveDoc':
        return translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.MLAD');
      case 'activiti$multipleReviewArchDoc':
        return translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.MRAD');
      default:
        return null;
    }
  }

  public static getWorkflowDefinitions(translation: TranslationService): object[] {
    return [
      { id: 'multipleReviewArchDoc', title: translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.MRAD'), },
      { id: 'multipleLevelApproveDoc', title: translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.MLAD'), },
      { id: 'groupReceiveDoc', title: translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.GRD'), },
      { id: 'groupApproveRejectDoc', title: translation.instant('APP.WORKFLOW.GENERAL.WORKFLOW_TYPE.GARD'), },
    ];
  }

  public static getTaskStatusOptions(translation: TranslationService): object[] {
    return [
      { id: TaskStatus.NOT_YET_STARTED, value: translation.instant('APP.WORKFLOW.GENERAL.TASK_STATUS.NOT_YET_STARTED') },
      { id: TaskStatus.IN_PROGRESS, value: translation.instant('APP.WORKFLOW.GENERAL.TASK_STATUS.IN_PROGRESS') },
      { id: TaskStatus.ON_HOLD, value: translation.instant('APP.WORKFLOW.GENERAL.TASK_STATUS.ON_HOLD') },
      { id: TaskStatus.CANCELLED, value: translation.instant('APP.WORKFLOW.GENERAL.TASK_STATUS.CANCELLED') },
      { id: TaskStatus.COMPLETED, value: translation.instant('APP.WORKFLOW.GENERAL.TASK_STATUS.COMPLETED') },
    ];
  }

  public static getSentStringValue(startDateIsoString: string, translation: TranslationService) {
    const startDate = Utils.dateFromISO8601(startDateIsoString);
    const daysCount = Utils.daysPassed(startDate);
    if (daysCount > 1) {
      return daysCount + ' ' + translation.instant('APP.WORKFLOW.GENERAL.DAYS_AGO');
    } else if (daysCount === 1) {
      return daysCount + ' ' + translation.instant('APP.WORKFLOW.GENERAL.DAY_AGO');
    } else {
      return translation.instant('APP.WORKFLOW.GENERAL.TODAY');
    }
  }

  public static formatDate(date: Date): string {
    return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
  }

  public static formatDateWithTime(date: Date): string {
    return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  }
}