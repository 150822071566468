/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { FilesComponent } from './components/files/files.component';
import { LibrariesComponent } from './components/libraries/libraries.component';
import { FavoriteLibrariesComponent } from './components/favorite-libraries/favorite-libraries.component';
import { SearchResultsComponent } from './components/search/search-results/search-results.component';
import { SearchLibrariesResultsComponent } from './components/search/search-libraries-results/search-libraries-results.component';
import { AppSharedRuleGuard, GenericErrorComponent, ExtensionRoute, ExtensionsDataLoaderGuard } from '@alfresco/aca-shared';
import { AuthGuard } from '@alfresco/adf-core';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { RecentFilesComponent } from './components/recent-files/recent-files.component';
import { SharedFilesComponent } from './components/shared-files/shared-files.component';
import { DetailsComponent } from './components/details/details.component';
import { HomeComponent } from './components/home/home.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { ViewProfileRuleGuard } from './components/view-profile/view-profile.guard';
import { Route } from '@angular/router';
import { AppArchiveRuleGuard } from './routing/archive.guard';
import { SharedLinkViewComponent } from './components/shared-link-view/shared-link-view.component';
import { TrashcanComponent } from './components/trashcan/trashcan.component';
import { ShellLayoutComponent } from '@alfresco/adf-core/shell';
import { ArchiveActComponent } from './components/archive/archive-act/archive-act.component';
import { ArchiveFileComponent } from './components/archive/archive-file/archive-file.component';
import { InventoryOfActComponent } from './components/archive/inventory-of-act/inventory-of-act.component';
import { PendingDocumentsComponent } from './components/archive/pending-documents/pending-documents.component';
import { ReportsComponent } from './components/archive/reports/reports.component';
import { SiteUserMembershipComponent } from './components/site-user-membership/site-user-membership.component';
import { CreateArchiveSignsComponent } from './components/archive-admin/create-archive-signs/create-archive-signs.component';
import { CreateArchiveComponent } from './components/archive-admin/create-archive/create-archive.component';
import { CreateRegistryBookComponent } from './components/archive-admin/create-registry-book/create-registry-book.component';
import { MyTasksComponent } from './components/workflow/my-tasks/my-tasks.component';
import { MyWorkflowsComponent } from './components/workflow/my-workflows/my-workflows.component';
import { StartWorkflowComponent } from './components/workflow/start-workflow/start-workflow.component';
import { TaskDetailsComponent } from './components/workflow/task-details/task-details.component';
import { WorkflowDetailsComponent } from './components/workflow/workflow-details/workflow-details.component';
import { AppArchiveAdminBasicRuleGuard } from './routing/archive-admin-basic.guard';
import { AppArchiveAdminStrictRuleGuard } from './routing/archive-admin-strict.guard';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { LleidaSignatureComponent } from './components/lleida/lleida-signature/lleida-signature.component';
import { ViewProcessesComponent } from './components/lleida/view-processes/view-processes.component';
import { WorkflowReportComponent } from './components/custom-reports/workflow-report/workflow-report.component';
import { ActivityFeedReportComponent } from './components/custom-reports/activity-feed-report/activity-feed-report.component';
import { MassArchivingComponent } from './components/archive/mass-archiving/mass-archiving.component';

export const CONTENT_ROUTES: ExtensionRoute[] = [
  {
    path: 'preview/s/:id',
    children: [
      {
        path: '',
        component: SharedLinkViewComponent,
        data: {
          title: 'APP.PREVIEW.TITLE'
        }
      }
    ]
  },
  {
    path: 'view',
    component: ShellLayoutComponent,
    children: [
      {
        path: ':nodeId',
        outlet: 'viewer',
        children: [
          {
            path: '',
            loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
          }
        ]
      }
    ]
  }
];

export const CONTENT_LAYOUT_ROUTES: Route = {
  path: '',
  canActivate: [ExtensionsDataLoaderGuard],
  children: [
    {
      path: 'profile',
      canActivate: [ViewProfileRuleGuard],
      component: ViewProfileComponent
    },
    {
      path: '',
    //   redirectTo: 'home',
    //   pathMatch: 'full'
    // },
    // {
    //   path: 'home',
      component: HomeComponent
    },
    {
      path: 'personal-files',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            sortingPreferenceKey: 'personal-files',
            title: 'APP.BROWSE.PERSONAL.TITLE',
            defaultNodeId: '-my-'
          }
        },
        {
          path: 'details/:nodeId',
          children: [
            {
              path: '',
              component: DetailsComponent,
              data: {
                navigateSource: 'personal-files'
              }
            },
            {
              path: ':activeTab',
              component: DetailsComponent,
              data: {
                title: 'APP.BROWSE.PERSONAL.PERMISSIONS.TITLE',
                navigateSource: 'personal-files'
              }
            }
          ]
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'personal-files/:folderId',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            title: 'APP.BROWSE.PERSONAL.TITLE',
            sortingPreferenceKey: 'personal-files'
          }
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'personal-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'libraries',
      children: [
        {
          path: '',
          component: LibrariesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.TITLE',
            sortingPreferenceKey: 'libraries'
          }
        }
      ]
    },
    {
      path: 'libraries/:folderId',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.MY_LIBRARIES.TITLE',
            sortingPreferenceKey: 'libraries-files'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ],
          data: {
            navigateSource: 'libraries'
          }
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'libraries/:folderId/user-membership',
      children: [
        {
          path: '',
          component: SiteUserMembershipComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.USER_MEMBERSHIP.TITLE',
            sortingPreferenceKey: 'libraries-user-membership'
          }
        }
      ]
    },
    {
      path: 'favorite',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'libraries'
        },
        {
          path: 'libraries',
          component: FavoriteLibrariesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.FAVORITE_LIBRARIES.TITLE',
            sortingPreferenceKey: 'favorite-libraries'
          }
        }
      ]
    },
    {
      path: 'favorite/libraries/:folderId',
      children: [
        {
          path: '',
          component: FilesComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.MENU.FAVORITE_LIBRARIES.TITLE',
            sortingPreferenceKey: 'libraries-files'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'libraries'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'favorite/libraries/:folderId/user-membership',
      children: [
        {
          path: '',
          component: SiteUserMembershipComponent,
          data: {
            title: 'APP.BROWSE.LIBRARIES.USER_MEMBERSHIP.TITLE',
            sortingPreferenceKey: 'libraries-user-membership'
          }
        }
      ]
    },
    {
      path: 'favorites',
      data: {
        sortingPreferenceKey: 'favorites'
      },
      children: [
        {
          path: '',
          component: FavoritesComponent,
          data: {
            title: 'APP.BROWSE.FAVORITES.TITLE',
            sortingPreferenceKey: 'favorites'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'favorites'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'favorites'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'recent-files',
      data: {
        sortingPreferenceKey: 'recent-files'
      },
      children: [
        {
          path: '',
          component: RecentFilesComponent,
          data: {
            title: 'APP.BROWSE.RECENT.TITLE'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'recent-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'recent-files'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'shared',
      children: [
        {
          path: '',
          data: {
            title: 'APP.BROWSE.SHARED.TITLE',
            sortingPreferenceKey: 'shared-files'
          },
          component: SharedFilesComponent
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'shared'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'shared'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ],
      canActivateChild: [AppSharedRuleGuard],
      canActivate: [AppSharedRuleGuard]
    },
    {
      path: 'trashcan',
      children: [
        {
          path: '',
          component: TrashcanComponent,
          data: {
            title: 'APP.BROWSE.TRASHCAN.TITLE',
            sortingPreferenceKey: 'trashcan'
          }
        }
      ]
    },
    {
      path: 'search',
      children: [
        {
          path: '',
          component: SearchResultsComponent,
          data: {
            title: 'APP.BROWSE.SEARCH.TITLE'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        },
        {
          path: 'view/:nodeId/:versionId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'search-libraries',
      children: [
        {
          path: '',
          component: SearchLibrariesResultsComponent,
          data: {
            title: 'APP.BROWSE.SEARCH.TITLE'
          }
        },
        {
          path: 'view/:nodeId',
          outlet: 'viewer',
          children: [
            {
              path: '',
              data: {
                navigateSource: 'search'
              },
              loadChildren: () => import('@alfresco/aca-content/viewer').then((m) => m.AcaViewerModule)
            }
          ]
        }
      ]
    },
    {
      path: 'nodes/:nodeId',
      children: [
        {
          path: '',
          loadChildren: () => import('@alfresco/aca-content/folder-rules').then((m) => m.AcaFolderRulesModule)
        }
      ]
    },
    {
      path: 'archive',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'file'
        },
        {
          path: 'pending-documents',
          component: PendingDocumentsComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE.MENU.PENDING_DOCUMENTS.TITLE'
          }
        },
        {
          path: 'file',
          component: ArchiveFileComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE.MENU.ARCHIVE_FILE.TITLE'
          }
        },
        {
          path: 'act',
          component: ArchiveActComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE.MENU.ARCHIVE_ACT.TITLE'
          }
        },
        {
          path: 'multiple-files',
          component: MassArchivingComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE.MENU.MASS_ARCHIVING.TITLE'
          }
        },
        {
          path: 'new-inventory-of-acts',
          component: InventoryOfActComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE.MENU.NEW_INVENTORY_OF_ACTS.TITLE'
          }
        },
        {
          path: 'reports',
          component: ReportsComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE.MENU.REPORTS.TITLE'
          }
        }
      ],
      canActivateChild: [AppArchiveRuleGuard],
      canActivate: [AppArchiveRuleGuard],
    },
    {
      path: 'archive-admin',
      canActivate: [AppArchiveAdminBasicRuleGuard],
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'create-registry-book'
        },
        {
          path: 'create-archive-site',
          component: CreateArchiveComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE_ADMIN.MENU.CREATE_ARCHIVE.TITLE'
          },
          canActivate: [AppArchiveAdminStrictRuleGuard]
        },
         {
          path: 'create-registry-book',
          component: CreateRegistryBookComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE_ADMIN.MENU.CREATE_REGISTRY_BOOK.TITLE'
          },
          canActivate: [AppArchiveAdminBasicRuleGuard]
        },
        {
          path: 'create-archive-signs',
          component: CreateArchiveSignsComponent,
          data: {
            title: 'APP.BROWSE.ARCHIVE_ADMIN.MENU.CREATE_ARCHIVE_SIGNS.TITLE'
          },
          canActivate: [AppArchiveAdminBasicRuleGuard]
        }
       ]
    },
    {
      path: 'workflow',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'my-tasks'
        },
        {
          path: 'my-tasks',
          component: MyTasksComponent,
          data: {
            title: 'APP.BROWSE.WORKFLOW.MENU.MY_TASKS.TITLE'
          }
        },
        {
          path: 'my-workflows',
          component: MyWorkflowsComponent,
          data: {
            title: 'APP.BROWSE.WORKFLOW.MENU.MY_WORKFLOWS.TITLE'
          }
        },
        {
          path: 'task-details/:taskId',
          component: TaskDetailsComponent,
          data: {
            title: 'APP.WORKFLOW.TASK_DETAILS.TITLE'
          }
        },
        {
          path: 'workflow-details/:workflowId',
          component: WorkflowDetailsComponent,
          data: {
            title: 'APP.WORKFLOW.WORKFLOW_DETAILS.TITLE'
          }
        },
        {
          path: 'start-workflow',
          component: StartWorkflowComponent,
          data: {
            title: 'APP.BROWSE.WORKFLOW.MENU.START_WORKFLOW.TITLE'
          }
        }
      ]
    },
    {
      path: 'custom-reports',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'workflow-report'
        },
        {
          path: 'workflow-report',
          component: WorkflowReportComponent,
          data: {
            title: 'APP.BROWSE.CUSTOM_REPORTS.MENU.WORKFLOW_REPORT.TITLE'
          }
        },
        {
          path: 'activity-feed-report',
          component: ActivityFeedReportComponent,
          data: {
            title: 'APP.BROWSE.CUSTOM_REPORTS.MENU.ACTIVITY_FEED_REPORT.TITLE'
          }
        }
      ]
    },
    {
      path: 'lleida',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'view-processes'
        },
        {
          path: 'view-processes',
          component: ViewProcessesComponent,
          data: {
            title: 'APP.BROWSE.LLEIDA.MENU.VIEW_PROCESSES.TITLE'
          }
        },
        {
          path: 'lleida-signature',
          component: LleidaSignatureComponent,
          data: {
            title: 'APP.BROWSE.LLEIDA.MENU.LLEIDA_SIGNATURE.TITLE'
          }
        }
      ]
    },
    {
      path: 'profile-settings',
      data: {
        title: 'APP.PROFILE_SETTINGS.PAGE_TITLE',
        sortingPreferenceKey: 'profile-settings'
      },
      component: ProfileSettingsComponent
    },
    {
      path: '**',
      component: GenericErrorComponent
    }
  ],
  canActivateChild: [AuthGuard]
};
