/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ServerSignService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, /* SnackbarInfoAction */ } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ImageAnnotatorComponent } from '../../components/image-annotator/image-annotator.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    ImageAnnotatorComponent,
  ],
  templateUrl: './server-sign-documents-in-directory.dialog.html',
  styleUrls: ['./server-sign-documents-in-directory.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-server-sign-documents-in-directory-dialog' }
})
export class ServerSignDocumentsInDirectoryDialogComponent implements OnInit {
  
  static maxPreviewSize = { width: 300, height: 400 };

  node: Node;
  annotationContentHtml: string = '';
  previewSize = { ...ServerSignDocumentsInDirectoryDialogComponent.maxPreviewSize };
  thumbnail = null;
  annotatorCoordinates = null;
  actionExecuting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private serverSignService: ServerSignService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<ServerSignDocumentsInDirectoryDialogComponent>
  ) {
    this.node = data.node.entry;
  }

  async ngOnInit() {
    try {
      const finalWidth: any = 283, finalHeight: any = 400;  // A4 portrait scaled
      this.previewSize = { width: finalWidth, height: finalHeight };
      const newDialogWidth = (finalWidth > 180 ? finalWidth : 180) + 52;
      this.dialogRef.updateSize(newDialogWidth);
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  annotatorMode(): ImageAnnotatorComponent.Mode {
    return ImageAnnotatorComponent.Mode.Rect;
  }

  onAnnotatorCoordinatesChanged(event): void {
    this.annotatorCoordinates = event;
  }

  onAnnotatorCoordinatesReset(): void {
    this.annotatorCoordinates = null;
  }

  async signDocumentsInDirectory() {
    if (!this.annotatorCoordinates) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('APP.SERVER_SIGN.MISSING_COORDINATES')));
      return;
    }
    this.actionExecuting = true;
    try {
      await this.serverSignService.signDocumentsInDirectory(`workspace://SpacesStore/${this.node.id}`, this.annotatorCoordinates.x, this.annotatorCoordinates.y, this.previewSize.width, this.previewSize.height);
      this.dialogRef.close(true);
      //this.store.dispatch(
      //  new SnackbarInfoAction(this.translation.instant('APP.SERVER_SIGN.DIRECTORY_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      this.dialogRef.close(false);
    }
    this.actionExecuting = false;
  }
}
