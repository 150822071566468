import { CustomApiCallService, HTTPMethod, PayloadType, ResponseType } from './custom-api-call.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServerSignService {
  constructor(private customApiCallService: CustomApiCallService) {}

  signDocument(documentNodeRef: string, x: number, y: number, containerWidth: number, containerHeight: number): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef,
      coordinates: JSON.stringify({ x, y, containerWidth, containerHeight }),
    };
    return this.customApiCallService.callService('server-signature/sign-document', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  signDocumentsInDirectory(directoryNodeRef: string, x: number, y: number, containerWidth: number, containerHeight: number): Promise<any> {
    const payload = {
      nodeRef: directoryNodeRef,
      coordinates: JSON.stringify({ x, y, containerWidth, containerHeight }),
    };
    return this.customApiCallService.callService('server-signature/sign-documents-in-directory', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }
}
