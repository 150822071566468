import { ContentApiService, ArchiveService, PageLayoutComponent, PageLayoutHeaderComponent, PageLayoutContentComponent } from '@alfresco/aca-shared';
import { NeocomDocumentSelectorComponent } from '../../neocom-document-selector/neocom-document-selector.component';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Node } from '@alfresco/js-api';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeActionsService } from '../../../services/node-actions.service';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    NeocomDocumentSelectorComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-mass-archiving',
  templateUrl: './mass-archiving.component.html',
  styleUrls: ['./mass-archiving.component.scss']
})
export class MassArchivingComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  cache: Map<string, any[]> = new Map();

  registries: any[] = [];
  organizationalUnits: any[] = [];
  archiveSigns: any[] = [];
  today = new Date();

  documentNodeRef: string = null;
  selectedDocuments: Node[] = [];
  initiallySelectedDocuments: Node[] = [];

  massArchivingForm = new FormGroup({
    selectedRegistry: new FormControl(undefined, Validators.required),
    selectedOrganizationalUnit: new FormControl(undefined, Validators.required),
    selectedArchiveSign: new FormControl(undefined, Validators.required),
    dateArchiving: new FormControl(this.today),
    dateSent: new FormControl(),
    subjectName: new FormControl(''),
    senderName: new FormControl(''),
    senderNumber: new FormControl(''),
    notes: new FormControl('')
  });

  constructor(
    private archiveService: ArchiveService,
    private contentApiService: ContentApiService,
    private nodeActionsService: NodeActionsService,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  get selectedRegistry() {
    return this.massArchivingForm.get('selectedRegistry');
  }

  get selectedOrganizationalUnit() {
    return this.massArchivingForm.get('selectedOrganizationalUnit');
  }

  get selectedArchiveSign() {
    return this.massArchivingForm.get('selectedArchiveSign');
  }

  async ngOnInit(): Promise<void> {
    this.registries = await this.archiveService.getRegistryBooks();

    this.selectedRegistry.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateOrganizationalUnits());

    this.selectedOrganizationalUnit.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateArchiveSigns());

    if (this.route.snapshot.queryParams.nodeIds) {
      const nodeIds = this.route.snapshot.queryParams.nodeIds.split(',');

      try {
        const selectedNodes = await Promise.all(
          nodeIds.map(nodeId => this.contentApiService.getNodeInfo(nodeId).toPromise())
        );
        this.initiallySelectedDocuments = selectedNodes;
      } catch (_) {
        return this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      }
    }
  }

  async preArchivalChecks(): Promise<void> {
    const requestBody = { ...this.massArchivingForm.value };
    const existingSubjects = await this.archiveService.getSubjectsSenderNumber(requestBody.selectedRegistry, requestBody.senderNumber);
    if (existingSubjects && existingSubjects.length > 0) {
      const duplicatesString = existingSubjects.map(el => el['title']).join(', ');
      const shouldContinue = await this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.ARCHIVE.MASS_ARCHIVING_DUPLICATE_SUBJECTS.TITLE',
          message: this.translation.instant('APP.ARCHIVE.MASS_ARCHIVING_DUPLICATE_SUBJECTS.CONTENT').replace('{0}', duplicatesString),
          yesLabel: 'APP.GENERAL.YES',
          noLabel: 'APP.GENERAL.CANCEL'
        },
        minWidth: '250px',
        maxWidth: '450px'
      }).afterClosed().toPromise();
      if (!shouldContinue) throw new Error('aborted');
    }
  }

  async massArchiving(): Promise<void> {
    if (this.massArchivingForm.invalid) return;

    try {
      await this.preArchivalChecks();
    } catch (_) {
      return;
    }

    const requestBody = { ...this.massArchivingForm.value };

    if (this.selectedDocuments.length === 0) {
      this.store.dispatch(new SnackbarErrorAction('No files selected. Please upload at least one PDF.'));
      return;
    }

    const documentNodeRefs = this.selectedDocuments.map(document => document.id);
    if (!documentNodeRefs || documentNodeRefs.length === 0 || documentNodeRefs.includes(null)) {
      this.store.dispatch(new SnackbarErrorAction('Could not find any valid document node references.'));
      return;
    }

    for (const documentNodeRef of documentNodeRefs) {
      try {
        const folderNodeRef = documentNodeRef ? `workspace://SpacesStore/${documentNodeRef}` : '';
        let documentName = this.selectedDocuments.find(doc => doc.id === documentNodeRef)?.name || '';
        documentName = this.removePdfExtension(documentName);

        const result = await this.archiveService.archiveSubject(
          folderNodeRef,
          requestBody.selectedRegistry,
          requestBody.selectedOrganizationalUnit,
          requestBody.selectedArchiveSign,
          undefined,
          undefined,
          requestBody.subjectName || documentName,
          requestBody.dateArchiving,
          requestBody.senderName,
          requestBody.senderNumber,
          requestBody.dateSent,
          requestBody.notes
        );

        const folderNodeGuid = result.nodeRef.substring(result.nodeRef.lastIndexOf('/') + 1);
        const folderNode = await this.contentApiService.getNodeInfo(folderNodeGuid).toPromise();
        const nodeParentId = this.nodeActionsService.getEntryParentId(folderNode);
        const url = `/libraries/${nodeParentId}`;

        this.router.navigateByUrl(url);
      } catch (error) {
        console.log({ error });
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      }
    }
  }

  private async updateOrganizationalUnits(): Promise<void> {
    this.selectedArchiveSign.setValue(undefined, { emitEvent: false });
    this.selectedOrganizationalUnit.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedRegistry.value;

    if (this.cache.has(nodeRef)) {
      this.organizationalUnits = this.cache.get(nodeRef);
    } else {
      this.organizationalUnits = await this.archiveService.getOrganizationalUnits(nodeRef);
      this.cache.set(nodeRef, this.organizationalUnits);
    }
  }

  private async updateArchiveSigns(): Promise<void> {
    this.selectedArchiveSign.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedOrganizationalUnit.value;

    if (this.cache.has(nodeRef)) {
      this.archiveSigns = this.cache.get(nodeRef);
    } else {
      this.archiveSigns = await this.archiveService.getArchiveSigns(nodeRef);
      this.cache.set(nodeRef, this.archiveSigns);
    }
  }

  private removePdfExtension(fileName: string): string {
    return fileName.endsWith('.pdf') ? fileName.slice(0, -4) : fileName;
  }

  onDocumentSelectorSelectionChanged(newSelection: Node[]) {
    this.selectedDocuments = newSelection;
  }

  onDocumentSelectorNavigate(index: number) {
    const node = this.selectedDocuments[index];
    const url = `#/libraries/${node.parentId}/(viewer:view/${node.id})?location=%2Flibraries%2F${node.parentId}`;
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
