<h2 mat-dialog-title>
  {{ (editing ? editTitle : createTitle) | translate }}
</h2>

<mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <mat-form-field class="adf-full-width">
            <input
                id="user-username-input"
                placeholder="{{ 'APP.SETTINGS.USERNAME' | translate }}"
                matInput
                required
                [readonly]="editing"
                [formControl]="fcUsername"/>

            <mat-hint *ngIf="fcUsername.dirty">
                <span *ngIf="fcUsername.errors?.required">
                    {{ 'APP.SETTINGS.USERNAME_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcUsername.errors?.required && fcUsername.errors?.message">
                    {{ fcUsername.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="user-first-name-input"
                matInput
                placeholder="{{ 'APP.SETTINGS.FIRST_NAME' | translate }}"
                required
                [formControl]="fcFirstName"/>

            <mat-hint *ngIf="fcFirstName.dirty">
                <span *ngIf="fcFirstName.errors?.required">
                    {{ 'APP.SETTINGS.FIRST_NAME_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcFirstName.errors?.required && fcFirstName.errors?.message">
                    {{ fcFirstName.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="user-last-name-input"
                matInput
                placeholder="{{ 'APP.SETTINGS.LAST_NAME' | translate }}"
                [formControl]="fcLastName"/>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="user-email-input"
                matInput
                placeholder="{{ 'APP.SETTINGS.EMAIL' | translate }}"
                required
                [formControl]="fcEmail"/>

            <mat-hint *ngIf="fcEmail.dirty">
                <span *ngIf="fcEmail.errors?.required">
                    {{ 'APP.SETTINGS.EMAIL_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcEmail.errors?.required && fcEmail.errors?.message">
                    {{ fcEmail.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="user-password-input"
                type="password"
                matInput
                placeholder="{{ 'APP.SETTINGS.PASSWORD' | translate }}"
                [required]="!editing"
                [formControl]="fcPassword"/>

            <mat-hint *ngIf="fcPassword.dirty">
                <span *ngIf="fcPassword.errors?.required">
                    {{ 'APP.SETTINGS.PASSWORD_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcPassword.errors?.required && fcPassword.errors?.message">
                    {{ fcPassword.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="user-password-repeat-input"
                type="password"
                matInput
                placeholder="{{ 'APP.SETTINGS.PASSWORD_REPEATED' | translate }}"
                [required]="!editing"
                [formControl]="fcPasswordRepeated"/>

            <mat-hint *ngIf="fcPasswordRepeated.dirty">
                <span *ngIf="fcPasswordRepeated.errors?.required">
                    {{ 'APP.SETTINGS.PASSWORD_REPEATED_REQUIRED' | translate }}
                </span>
                <span *ngIf="fcPasswordRepeated.value !== fcPassword.value">
                    {{ 'APP.SETTINGS.PASSWORD_REPEATED_NOT_MATCH' | translate }}
                </span>
                <span *ngIf="!fcPasswordRepeated.errors?.required && !fcPasswordRepeated.errors?.not_match && fcPasswordRepeated.errors?.message">
                    {{ fcPasswordRepeated.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
        mat-button
        id="adf-folder-cancel-button"
        (click)="closeDialog()">
        {{ 'CORE.FOLDER_DIALOG.CANCEL_BUTTON.LABEL' | translate }}
    </button>

    <button *ngIf="!editing"
            class="adf-dialog-action-button"
            id="adf-folder-create-button"
            mat-button
            (click)="submit(false)"
            [disabled]="!form.valid">
        {{ 'APP.DIALOGS.CREATE_MORE' | translate }}
    </button>

    <button class="adf-dialog-action-button"
            color="primary"
            id="adf-folder-create-button"
            mat-button
            (click)="submit(true)"
            [disabled]="!form.valid">
        {{
        (editing
        ? 'CORE.FOLDER_DIALOG.UPDATE_BUTTON.LABEL'
        : 'CORE.FOLDER_DIALOG.CREATE_BUTTON.LABEL'
        ) | translate
        }}
    </button>
</mat-dialog-actions>
