/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { RuleContext } from '@alfresco/adf-extensions';
import { Constants } from './constants';
import { Group } from '@alfresco/js-api';

/**
 * Checks if user is admin.
 * JSON ref: `user.isAdmin`
 */
export const isAdmin = (context: RuleContext): boolean => context.profile.isAdmin;

export function isArchiveUser(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_ARCHIVE_USERS_ID);
    return Boolean(groupEntry);
  }
  return false;
}
  
export function isArchiveAdmin(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_ARCHIVE_ADMINS_ID);
    return Boolean(groupEntry);
  }
  return false;
}

export function isArchiveAdminOrCreator(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_ARCHIVE_ADMINS_ID || g.id === "GROUP_" + Constants.GROUP_ARCHIVE_CREATORS_ID);
    return Boolean(groupEntry);
  }
  return false;
}

export function isReportsUser(context: RuleContext): boolean {
  return isWorkflowReportingUser(context) || isActivityFeedUser(context);
}

export function isWorkflowReportingUser(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_WORKFLOW_REPORTING_ID);
    return Boolean(groupEntry);
  }
  return false;
}

export function isActivityFeedUser(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_ACTIVITY_FEED_ID);
    return Boolean(groupEntry);
  }
  return false;
}

export function isLleidaUser(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_LLEIDA_ID);
    return Boolean(groupEntry);
  }
  return false;
}

export function isCompanySignatureUser(context: RuleContext): boolean {
  if (context.profile.groups) {
    const groupEntry = context.profile.groups.find((g: Group) => g.id === "GROUP_" + Constants.GROUP_COMPANY_SIGNATURE_ID);
    return Boolean(groupEntry);
  }
  return false;
}
