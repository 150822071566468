import { CustomApiCallService, HTTPMethod, ResponseType } from './custom-api-call.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsService {
  constructor(private customApiCallService: CustomApiCallService) {}

  getReportTasks(itemsPerPage?: number, page?: number, workflowType?: string, username?: string, startedAfter?: Date, startedBefore?: Date): Promise<object> {
    const queryParts: string[] = [];
    if (itemsPerPage)
      queryParts.push(`itemsPerPage=${itemsPerPage}`);
    if (page)
      queryParts.push(`page=${page}`);
    if (workflowType)
      queryParts.push(`workflowType=${workflowType}`);
    if (username)
      queryParts.push(`user=${username}`);
    if (startedAfter)
      queryParts.push(`startedAfter=${startedAfter.toISOString()}`);
    if (startedBefore)
      queryParts.push(`startedBefore=${startedBefore.toISOString()}`);
    const query = queryParts.length > 0 ? `?${queryParts.join('&')}` : '';

    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`neoworkflow/get-report-tasks${query}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          resolve(value);
        })
        .catch((err) => reject(err));
    });
  }

  getSiteActivity(site: string, username?: string, dateFrom?: Date, dateTo?: Date): Promise<object[]> {
    const queryParts: string[] = [];
    queryParts.push(`site=${site}`);
    if (username)
      queryParts.push(`username=${username}`);
    if (dateFrom)
      queryParts.push(`dateFrom=${dateFrom.toISOString()}`);
    if (dateTo)
      queryParts.push(`dateTo=${dateTo.toISOString()}`);
    const query = `?${queryParts.join('&')}`;

    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`neoworkflow/site-activity-report${query}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          resolve(value);
        })
        .catch((err) => reject(err));
    });
  }
}
