import { ArchiveAdminService, ArchiveService, ContentApiService, Constants, PageLayoutComponent, PageLayoutHeaderComponent, PageLayoutContentComponent, SiteRoles } from '@alfresco/aca-shared';
import { AppStore, SnackbarInfoAction, SnackbarErrorAction, getUserSiteRoles, getUserProfile } from '@alfresco/aca-shared/store';
import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataCellEvent, DataRowActionEvent, DataTableModule } from '@alfresco/adf-core';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { Group, SiteRole } from '@alfresco/js-api';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-create-archive-signs',
  templateUrl: './create-archive-signs.component.html',
  styleUrls: ['./create-archive-signs.component.scss']
})
export class CreateArchiveSignsComponent implements OnInit {

  archives: any[] = [];
  userSiteRoles: SiteRole[] = [];
  userGroups: Group[] = [];
  archivesFiltered: any[] = [];
  registries: any[] = [];
  orgUnits: any[] = [];
  archSigns: any[] = [];
  groups: string[] = [];
  selectedOrgUnit = null;
  isEditingOrgUnit: boolean = false;
  editingOrgUnitNodeRef: string = null;
  isEditingArchSign: boolean = false;
  editingArchSignNodeRef: string = null;

  orgUnitsSchema: object[];
  archSignsSchema: object[];

  selectForm = new FormGroup({
    selectedArchive: new FormControl(undefined),
    selectedRegistry: new FormControl(undefined),
  });

  orgUnitForm = new FormGroup({
    orgIndex: new FormControl(undefined, [ Validators.required, Validators.pattern(/^\d*$/) ]),
    title: new FormControl(undefined, Validators.required),
  });

  archiveSignForm = new FormGroup({
    asIndex: new FormControl(undefined, [ Validators.required, Validators.pattern(/^\d*$/) ]),
    asTitle: new FormControl(undefined, Validators.required),
    months: new FormControl(undefined, Validators.required)
  });

  constructor(
    private archiveAdminService: ArchiveAdminService,
    private archiveService: ArchiveService,
    private contentApiService: ContentApiService,
    private store: Store<AppStore>,
    private translation: TranslateService,
    private dialog: MatDialog,
  ) {
    this.initializeSchemas();

    translation.onLangChange.subscribe(() => {
      this.initializeSchemas();
    });
  }

  private initializeSchemas() {
    this.orgUnitsSchema = [
      {
        type: 'text',
        key: 'index',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.INDEX'),
        sortable: true,
      },    
      {
        type: 'text',
        key: 'title',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.TITLE'),
        sortable: true,
      },
    ];
    this.archSignsSchema = [
      {
        type: 'text',
        key: 'index',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.INDEX'),
        sortable: true,
      },    
      {
        type: 'text',
        key: 'title',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.TITLE'),
        sortable: true,
      },    
      {
        type: 'text',
        key: 'monthsFormatted',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.MONTHS_TO_KEEP'),
        sortable: true,
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.getArchives(),
      this.getUserSiteRoles(),
      this.getUserGroups(),
    ]);
    this.setupArchives();
  }

  onChangeArchive(event) {
    this.getRegistryBooks(event.value);
    this.onRowOrgUnitsUnselect(null);
  }

  onChangeRegistry(event) {
    this.getOrgUnits(event.value);
    this.onRowOrgUnitsUnselect(null);
  }

  getSelectedRegistry() {
    return this.selectForm.get('selectedRegistry').value;
  }

  onShowRowActionsMenuOrgUnits(event: DataCellEvent) {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.EDIT_ORG_UNIT')
    };
    const removeAction = {
      id: 1,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.REMOVE_ORG_UNIT')
    };
    const selectAction = {
      id: 2,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.SELECT_ORG_UNIT')
    };
    event.value.actions = [editAction, removeAction, selectAction]
  }

  onExecuteRowActionOrgUnits(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      this.editOrgUnit(args.row.obj);
    } else if (args.action.id === 1) {
      this.removeOrgUnit(args.row.obj);
    } else if (args.action.id === 2) {
      this.onRowOrgUnitsSelect({
        detail: {
          row: {
            obj: args.row.obj,
          },
        },
      });
    }
  }

  editOrgUnit(obj) {
    this.isEditingOrgUnit = true;
    this.editingOrgUnitNodeRef = obj['nodeRef'];
    this.orgUnitForm.get('orgIndex').setValue(obj['index']);
    this.orgUnitForm.get('title').setValue(obj['title']);
  }

  cancelEditOrgUnit() {
    this.isEditingOrgUnit = false;
    this.editingOrgUnitNodeRef = null;
    this.orgUnitForm.get('orgIndex').setValue('');
    this.orgUnitForm.get('title').setValue('');
  }

  removeOrgUnit(obj) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'APP.ARCHIVE_ADMIN.REMOVE_ORG_UNIT_TITLE',
        message: 'APP.ARCHIVE_ADMIN.REMOVE_ORG_UNIT_MESSAGE',
        yesLabel: 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL',
        noLabel: 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL',
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeOrgUnitCommit(obj);
        this.onRowOrgUnitsUnselect(null);
      }
    });
  }

  onRowOrgUnitsSelect(event) {
    if (this.selectedOrgUnit && this.selectedOrgUnit['nodeRef'] === event.detail.row.obj['nodeRef'])
      return;
    this.selectedOrgUnit = event.detail.row.obj;
    if (this.isEditingArchSign) {
      this.cancelEditArchSign();
    }
    this.getArchSigns(
      this.selectedOrgUnit['nodeRef']);
    this.archiveSignForm.get('asIndex').setValue(
      this.selectedOrgUnit['index']);
  }

  onRowOrgUnitsUnselect(_) {
    // not actually triggered by the datatable component, but it is used internally
    this.selectedOrgUnit = null;
    this.archSigns = [];
    if (this.isEditingOrgUnit) {
      this.cancelEditOrgUnit();
    }
    if (this.isEditingArchSign) {
      this.cancelEditArchSign();
    }
  }

  async createOrgUnit(): Promise<void> {
    if (this.selectForm.invalid || this.orgUnitForm.invalid)
      return;

    const selectedRegistry = this.getSelectedRegistry();
    const requestBody = { ...this.orgUnitForm.value };

    try {
      await this.archiveAdminService.createOrganizationalUnit(
        selectedRegistry,
        requestBody.orgIndex,
        requestBody.title,
        this.groups
      );

      this.getOrgUnits(selectedRegistry);

      this.orgUnitForm.reset();
      this.onRowOrgUnitsUnselect(null);

      this.store.dispatch(
            new SnackbarInfoAction(
                  this.translation.instant('APP.ARCHIVE_ADMIN.CREATE_ORG_UNIT_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
            new SnackbarErrorAction(
                  this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async updateOrgUnit() {
    if (this.selectForm.invalid || this.orgUnitForm.invalid)
      return;

    const selectedRegistry = this.getSelectedRegistry();
    const requestBody = { ...this.orgUnitForm.value };

    try {
      await this.archiveAdminService.updateOrganizationalUnit(
        this.editingOrgUnitNodeRef,
        requestBody.orgIndex,
        requestBody.title,
        this.groups
      );

      this.getOrgUnits(selectedRegistry);

      this.orgUnitForm.reset();
      this.isEditingOrgUnit = false;
      this.editingOrgUnitNodeRef = null;
      this.onRowOrgUnitsUnselect(null);

      this.store.dispatch(
            new SnackbarInfoAction(
                  this.translation.instant('APP.ARCHIVE_ADMIN.UPDATE_ORG_UNIT_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
            new SnackbarErrorAction(
                  this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async removeOrgUnitCommit(obj) {
    const nodeRef = obj['nodeRef'];
    const nodeId = nodeRef.substring(nodeRef.lastIndexOf('/') + 1);
    try { 
      await this.contentApiService.deleteNode(nodeId);

      setTimeout(() => {
        this.getOrgUnits(
          this.getSelectedRegistry());
      }, Constants.FILESYSTEM_UPDATE_TIMEOUT_VALUE);

      this.store.dispatch(
        new SnackbarInfoAction(
              this.translation.instant('APP.ARCHIVE_ADMIN.REMOVE_ORG_UNIT_SUCCESS')));
    } catch (e) {
      this.store.dispatch(
          new SnackbarErrorAction(
              this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  onShowRowActionsMenuArchSigns(event: DataCellEvent) {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.EDIT_ARCH_SIGN')
    };
    const removeAction = {
      id: 1,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.REMOVE_ARCH_SIGN')
    };
    event.value.actions = [editAction, removeAction]
  }

  onExecuteRowActionArchSigns(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      this.editArchSign(args.row.obj);
    } else if (args.action.id === 1) {
      this.removeArchSign(args.row.obj);
    }       
  } 

  editArchSign(obj: object) {
    this.isEditingArchSign = true;
    this.editingArchSignNodeRef = obj['nodeRef'];
    this.archiveSignForm.get('asIndex').setValue(obj['index']);
    this.archiveSignForm.get('asTitle').setValue(obj['title']);
    this.archiveSignForm.get('months').setValue(obj['months']);
  }

  cancelEditArchSign() {
    this.isEditingArchSign = false;
    this.editingArchSignNodeRef = null;
    this.archiveSignForm.get('asIndex').setValue('');
    this.archiveSignForm.get('asTitle').setValue('');
    this.archiveSignForm.get('months').setValue(null);
  }

  removeArchSign(obj) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'APP.ARCHIVE_ADMIN.REMOVE_ARCH_SIGN_TITLE',
        message: 'APP.ARCHIVE_ADMIN.REMOVE_ARCH_SIGN_MESSAGE',
        yesLabel: 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL',
        noLabel: 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL',
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeArchSignCommit(obj);
        if (this.isEditingArchSign && this.editingArchSignNodeRef === obj['nodeRef']) {
          this.cancelEditArchSign();
        }
      }
    });
  }

  async createArchSign() {
    if (this.selectForm.invalid || !this.selectedOrgUnit || this.archiveSignForm.invalid)
      return;

    const orgUnitNodeRef = this.selectedOrgUnit['nodeRef'];
    const requestBody = { ...this.archiveSignForm.value };

    try {
      await this.archiveAdminService.createArchiveSign(
        orgUnitNodeRef,
        requestBody.asIndex,
        requestBody.asTitle,
        requestBody.months
      );
      
      this.getArchSigns(orgUnitNodeRef);

      this.archiveSignForm.reset();
      this.archiveSignForm.get('asIndex').setValue(
        this.selectedOrgUnit['index']);

      this.store.dispatch(
            new SnackbarInfoAction(
                  this.translation.instant('APP.ARCHIVE_ADMIN.CREATE_ARCH_SIGN_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
            new SnackbarErrorAction(
                  this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async updateArchSign() {
    if (this.selectForm.invalid || !this.selectedOrgUnit || this.archiveSignForm.invalid)
      return;

    const orgUnitNodeRef = this.selectedOrgUnit['nodeRef'];
    const requestBody = { ...this.archiveSignForm.value };

    try {
      await this.archiveAdminService.updateArchiveSign(
        this.editingArchSignNodeRef,
        requestBody.asIndex,
        requestBody.asTitle,
        requestBody.months
      );

      this.getArchSigns(orgUnitNodeRef);

      this.archiveSignForm.reset();
      this.isEditingArchSign = false;
      this.editingArchSignNodeRef = null;
      this.archiveSignForm.get('asIndex').setValue(
        this.selectedOrgUnit['index']);

      this.store.dispatch(
            new SnackbarInfoAction(
                  this.translation.instant('APP.ARCHIVE_ADMIN.UPDATE_ARCH_SIGN_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
            new SnackbarErrorAction(
                  this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async removeArchSignCommit(obj) {
    const nodeRef = obj['nodeRef'];
    const nodeId = nodeRef.substring(nodeRef.lastIndexOf('/') + 1);
    try { 
      await this.contentApiService.deleteNode(nodeId);

      setTimeout(() => {
        this.getArchSigns(
          this.selectedOrgUnit['nodeRef']);
      }, Constants.FILESYSTEM_UPDATE_TIMEOUT_VALUE);

      this.store.dispatch(
        new SnackbarInfoAction(
              this.translation.instant('APP.ARCHIVE_ADMIN.REMOVE_ARCH_SIGN_SUCCESS')));
    } catch (e) {
      this.store.dispatch(
          new SnackbarErrorAction(
              this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async getArchives(){
    this.archives = await this.archiveService.getArchives();
  }

  async getUserSiteRoles() {
    this.userSiteRoles = await new Promise((resolve, _) => this.store.select(getUserSiteRoles).subscribe(userSiteRoles => resolve(userSiteRoles)));
  }

  async getUserGroups() {
    this.userGroups = await new Promise((resolve, _) => this.store.select(getUserProfile).subscribe(profile => resolve(profile.groups)));
  }

  setupArchives(){
    const isArchiveAdmin = Boolean(this.userGroups.find((g: Group) => g.id === 'GROUP_' + Constants.GROUP_ARCHIVE_ADMINS_ID));
    if (!isArchiveAdmin) {
      this.archivesFiltered = this.archives.filter(archive =>
        this.userSiteRoles.find(userSiteRole =>
          userSiteRole.site.id === archive['id']
          && (<string[]>[SiteRoles.MANAGER, SiteRoles.COLLABORATOR]).indexOf(userSiteRole.role) !== -1));
    } else {
      this.archivesFiltered = this.archives;
    }

    if (this.archivesFiltered.length > 0) {
      const id = this.archivesFiltered[0]['id'];
      this.selectForm.get('selectedArchive').setValue(id);
      this.getRegistryBooks(id);
    }
  }

  async getRegistryBooks(id: string) {
    this.registries = await this.archiveService.getRegistryBooks(id);
    if (this.registries && this.registries.length > 0) {
      const nodeRef = this.registries[0]['nodeRef'];
      this.selectForm.get('selectedRegistry').setValue(nodeRef);
      this.getOrgUnits(nodeRef);
    }
  }

  async getOrgUnits(nodeRef: string) {
    this.orgUnits = await this.archiveService.getOrganizationalUnits(nodeRef);
  }

  async getArchSigns(nodeRef: string) {
    const archSigns = await this.archiveService.getArchiveSigns(nodeRef);
    this.archSigns = archSigns.map(archSign => ({
      ...archSign,
      monthsFormatted: archSign['months'] === '0'
        ? this.translation.instant('APP.ARCHIVE_ADMIN.AS_MONTHS_FOREVER')
        : archSign['months'],
    }));
  }
}
