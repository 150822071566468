<h2 mat-dialog-title>
  {{ (editing ? editTitle : createTitle) | translate }}
</h2>

<mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <mat-form-field class="adf-full-width">
            <input
                id="group-id-input"
                placeholder="{{ 'APP.SETTINGS.GROUP_IDENTIFIER' | translate }}"
                matInput
                required
                [readonly]="editing"
                [formControl]="fcId"/>

            <mat-hint *ngIf="fcId.dirty">
                <span *ngIf="fcId.errors?.required">
                    {{ 'APP.SETTINGS.GROUP_IDENTIFIER_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcId.errors?.required && fcId.errors?.message">
                    {{ fcId.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="group-display-name-input"
                matInput
                placeholder="{{ 'APP.SETTINGS.DISPLAY_NAME' | translate }}"
                required
                [formControl]="fcDisplayName"/>

            <mat-hint *ngIf="fcDisplayName.dirty">
                <span *ngIf="fcDisplayName.errors?.required">
                    {{ 'APP.SETTINGS.GROUP_DISPLAY_NAME_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcDisplayName.errors?.required && fcDisplayName.errors?.message">
                    {{ fcDisplayName.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
        mat-button
        id="adf-folder-cancel-button"
        (click)="closeDialog()">
        {{ 'CORE.FOLDER_DIALOG.CANCEL_BUTTON.LABEL' | translate }}
    </button>

    <button *ngIf="!editing"
            class="adf-dialog-action-button"
            id="adf-folder-create-button"
            mat-button
            (click)="submit(false)"
            [disabled]="!form.valid">
        {{ 'APP.DIALOGS.CREATE_MORE' | translate }}
    </button>

    <button class="adf-dialog-action-button"
            color="primary"
            id="adf-folder-create-button"
            mat-button
            (click)="submit(true)"
            [disabled]="!form.valid">
        {{
        (editing
        ? 'CORE.FOLDER_DIALOG.UPDATE_BUTTON.LABEL'
        : 'CORE.FOLDER_DIALOG.CREATE_BUTTON.LABEL'
        ) | translate
        }}
    </button>
</mat-dialog-actions>
