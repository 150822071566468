<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'button'">
    <button
      [id]="actionRef.id"
      [color]="data?.color || color"
      mat-button
      [attr.aria-label]="actionRef.description || actionRef.title | translate"
      [attr.title]="actionRef.description || actionRef.title | translate"
      [matMenuTriggerFor]="menu"
      [disabled]="actionRef.disabled"
      #matTrigger="matMenuTrigger"
    >
      <span *ngIf="actionRef.title">{{ actionRef.title | translate }}</span>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'flat-button'">
    <button
      [id]="actionRef.id"
      [color]="data?.color || color"
      mat-flat-button
      [attr.aria-label]="actionRef.description || actionRef.title | translate"
      [attr.title]="actionRef.description || actionRef.title | translate"
      [matMenuTriggerFor]="menu"
      [disabled]="actionRef.disabled"
      #matTrigger="matMenuTrigger"
      (mouseenter)="onHover($event, true)"
      (mouseleave)="onHover($event, false)"
      style="transition: background-color 0.2s ease;"
    >
      <span *ngIf="actionRef.title">{{ actionRef.title | translate }}</span>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'stroked-button'">
    <button
      [id]="actionRef.id"
      [color]="'primary'"
      mat-stroked-button
      [attr.aria-label]="actionRef.description || actionRef.title | translate"
      [attr.title]="actionRef.description || actionRef.title | translate"
      [matMenuTriggerFor]="menu"
      [disabled]="actionRef.disabled"
      #matTrigger="matMenuTrigger"
    >
      <span *ngIf="actionRef.title">{{ actionRef.title | translate }}</span>
    </button>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <button
      [id]="actionRef.id"
      [color]="data?.color || color"
      mat-icon-button
      [attr.aria-label]="actionRef.description || actionRef.title | translate"
      [attr.title]="actionRef.description || actionRef.title | translate"
      [matMenuTriggerFor]="menu"
      [disabled]="actionRef.disabled"
      #matTrigger="matMenuTrigger"
    >
      <adf-icon *ngIf="actionRef.icon" [value]="actionRef.icon"></adf-icon>
    </button>
  </ng-container>
</ng-container>

<mat-menu #menu="matMenu" [overlapTrigger]="false" [xPosition]="'before'">
  <ng-container *ngFor="let child of actionRef.children; trackBy: trackByActionId">
    <ng-container [ngSwitch]="child.type">
      <ng-container *ngSwitchCase="'custom'">
        <adf-dynamic-component [id]="child.component" [data]="child.data"></adf-dynamic-component>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <app-toolbar-menu-item [actionRef]="child" [menuId]="actionRef.id"></app-toolbar-menu-item>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
