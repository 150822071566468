import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { Group } from '@alfresco/js-api';
import { TranslationService } from '@alfresco/adf-core';
import { GroupService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule
  ],
  selector: 'aca-create-group-dialog',
  templateUrl: './create-group-dialog.component.html',
  styleUrls: ['./create-group-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateGroupDialogComponent implements OnInit {

  form: FormGroup;
  
  editTitle = 'APP.SETTINGS.EDIT_GROUP';
  createTitle = 'APP.SETTINGS.NEW_GROUP';

  hasProcessedEntity: Boolean = false;

  fcId: FormControl;
  fcDisplayName: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialogRef<CreateGroupDialogComponent>,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private groupService: GroupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
  }

  get editing(): boolean {
    return Boolean(this.data);
  }

  ngOnInit() {
    let id = this.data ? this.data.id : '';
    let display_name = this.data ? this.data.displayName : '';

    const validators = {
      id: [
        Validators.required,
      ],
      display_name: [
        Validators.required,
      ],
    };

    this.form = this.formBuilder.group({
      id: [ id, validators.id ],
      display_name: [ display_name, validators.display_name ],
    });

    this.fcId = this.form.controls['id'] as FormControl;
    this.fcDisplayName = this.form.controls['display_name'] as FormControl;
  }

  get id(): string {
    const { id } = this.form.value;

    return (id || '').trim();
  }

  get display_name(): string {
    const { display_name } = this.form.value;

    return (display_name || '').trim();
  }

  private create(): Promise<Group> {
    return this.groupService.createGroup(this.id, this.display_name);
  }

  private edit(): Promise<Group> {
    return this.groupService.updateGroup(this.id, this.display_name);
  }

  closeDialog() {
    this.dialog.close(this.hasProcessedEntity);
  }

  submit(close=true) {
    const { form, editing } = this;

    if (!form.valid) { return; }

    (editing ? this.edit() : this.create())
      .then(_ => {
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant(this.editing ? 'APP.SETTINGS.GROUP_UPDATED' : 'APP.SETTINGS.GROUP_CREATED')
        ));
        this.hasProcessedEntity = true;
        if (editing || close)
          this.closeDialog();
        else if (!editing && !close)
          form.reset();
      })
      .catch(error => this.handleError(error));
  }

  handleError(error: any): any {
    let errorMessage = 'CORE.MESSAGES.ERRORS.GENERIC';

    try {
      const { error: { statusCode } } = JSON.parse(error.message);

      if (statusCode === 409) {
        errorMessage = 'APP.SETTINGS.EXISTENT_GROUP';
      }
    } catch (err) { /* Do nothing, keep the original message */ }

    this.store.dispatch(new SnackbarErrorAction(
      this.translation.instant(errorMessage)));

    return error;
  }
}