export class Constants {
    
    public static GROUP_MEMBERSHIP_UPDATE_TIMEOUT_VALUE = 16000;
    public static AVATAR_UPDATE_TIMEOUT_VALUE = 1000;
    public static FILESYSTEM_UPDATE_TIMEOUT_VALUE = 500;
    public static CONTINUED_TYPING_THRESHOLD_VALUE = 750;

    // if changing, note duplicates in aca-shared/rules/src/constants.ts
    public static GROUP_ARCHIVE_USERS_ID = "ARCHIVE_USERS";
    public static GROUP_ARCHIVE_ADMINS_ID = "ARCHIVE_ADMINS";
    public static GROUP_ARCHIVE_CREATORS_ID = "ARCHIVE_CREATORS";
};