import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent, debounce } from '@alfresco/aca-shared';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { Utils } from '../../workflow/utils';
import { TranslationService } from '@alfresco/adf-core';
import { CustomReportsService } from '@alfresco/aca-shared';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BreadcrumbModule,
    PageLayoutComponent,
    PageLayoutContentComponent,
    PageLayoutHeaderComponent,
    MatFormFieldModule,
    MatButtonModule,
    MatOptionModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './workflow-report.component.html',
  styleUrls: ['./workflow-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-workflow-report'
})
export class WorkflowReportComponent implements OnInit {
  
  workflowTypes: any[];
  today = new Date();
  showDataTable: boolean = false;
  showTaskMessage: boolean = false;
  isLoading: boolean = false;
  tableColumns: any[];
  tableRows: any[] = [];

  _taskStatusOptions: object[];
  itemsPerPageOptions: number[] = [10, 25, 50, 75, 100];
  itemsPerPage: number = 50;
  currentPage: number = 1;
  totalPages: number;

  localData: any[] = [];

  workflowReportForm = new FormGroup({
    workflowType: new FormControl('all', Validators.required),
    user: new FormControl(undefined),
    dateStartedAfter: new FormControl(),
    dateStartedBefore: new FormControl(),
    itemsPerPage: new FormControl(this.itemsPerPage, Validators.required)
  });
  dataRequestDebounce: Function;

  private formSubmitCalled: boolean = false;

  constructor(
    private customReportsService: CustomReportsService,
    private router: Router,
    private translation: TranslationService)
  {
    this.initializeWorkflowTypes();
    this.initializeTableColumns();
    this.updateTaskStatusOptions();

    translation.translate.onLangChange.subscribe(() => {
      this.initializeWorkflowTypes();
      this.initializeTableColumns();
      this.updateTaskStatusOptions();
      if (this.localData.length > 0) {
        this.generateTable(this.localData);
      }
    });
  }

  async ngOnInit() {
    this.dataRequestDebounce = debounce(300);
  }

  private updateTaskStatusOptions(): void {
    this._taskStatusOptions = Utils.getTaskStatusOptions(this.translation);
  }

  private initializeWorkflowTypes(): void {
    const workflowDefinitions = Utils.getWorkflowDefinitions(this.translation);
    this.workflowTypes = [
      { id: 'all', title: this.translation.instant('APP.CUSTOM_REPORTS.GENERAL.ALL') },
      ...workflowDefinitions
    ];
  }

  private initializeTableColumns(): void {
    this.tableColumns = [
      { key: 'workflowName', title: this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.WORKFLOW'), type: 'text' },
      { key: 'initiator.fullName', title: this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.FROM'), type: 'text' },
      { key: 'started', title: this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.DATE'), type: 'text' },
      { key: 'name', title: this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.NAME_DESCRIPTION'), type: 'text' },
      { key: 'assignee.fullName', title: this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.TO'), type: 'text' },
      { key: 'state', title: this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.STATUS'), type: 'text' }
    ];
  }

  generateTable(data: any[]) {
    if (data && data.length > 0) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.tableRows = data.slice(startIndex, endIndex).map((item) => this.createRow(item));

      this.showDataTable = true;
      this.showTaskMessage = false;
    } else {
      this.showDataTable = false;
      this.showTaskMessage = true;
    }
    this.isLoading = false;
  }

  resetCurrentPage(): void {
    this.currentPage = 1;
  }

  private createRow(item: any): any {
    const row = {};
    this.tableColumns.forEach((column) => {
      const value = this.extractValue(item, column.key);
      row[column.key] = this.formatValue(column, value, item);
    });
    row['id'] = item.id;
    row['workflowId'] = item.workflowId;
    return row;
  }

  private extractValue(item: any, key: string): any {
    const keys = key.split('.');
    let value = item;
    keys.forEach((k) => {
      value = value[k];
    });
    return value;
  }

  private formatValue(column: any, value: any, item?: any): any {
    switch (column.key) {
      case 'started':
        return this.formatDate(value);
      case 'state':
        return this.translateStatus(value);
      case 'workflowName':
        return item.workflowName;
      case 'name':
        return item.name;
      default:
        return value;
    }
  }

  onTaskClick(id: any): void {
    if (id) {
      this.router.navigate([`/workflow/task-details/${id}`]);
    }
  }

  onWorkflowClick(workflowId: any): void {
    if (workflowId) {
      this.router.navigate([`/workflow/workflow-details/${workflowId}`]);
    }
  }

  private translateStatus(status: string): string {
    const statusMap = {
      NOT_YET_STARTED: 'Not Yet Started',
      IN_PROGRESS: 'In Progress',
      ON_HOLD: 'On Hold',
      CANCELLED: 'Cancelled',
      COMPLETED: 'Completed'
    };

    const statusElement = this._taskStatusOptions.find((el) => el['id'] === statusMap[status]);
    return statusElement ? statusElement['value'] : this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
  }

  private formatDate(value: any): string {
    const parsedDate = new Date(Date.parse(value));

    if (!isNaN(parsedDate.getTime())) {
      return Utils.formatDateWithTime(parsedDate);
    } else {
      return value;
    }
  }

  printTable() {
    const title = this.translation.instant('APP.CUSTOM_REPORTS.WORKFLOW_REPORT.TITLE');
    const tableContent = this.generatePrintableTableContent();

    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: center;
            }
            th {
              background-color: #f2f2f2;
            }
            th:nth-child(1), td:nth-child(1) {
              width: 35%;
            }
            th:nth-child(3), td:nth-child(3) {
              width: 100px;
            }
          </style>
        </head>
        <body>
          <h2>${title}</h2>
          ${tableContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  }

  private generatePrintableTableContent(): string {
    let content = '<table><thead><tr>';

    this.tableColumns.forEach(column => {
      content += `<th>${column.title}</th>`;
    });
    content += '</tr></thead><tbody>';

    this.tableRows.forEach(row => {
      content += '<tr>';
      this.tableColumns.forEach(column => {
        content += `<td>${row[column.key]}</td>`;
      });
      content += '</tr>';
    });

    content += '</tbody></table>';

    return content;
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.generateTable(this.localData);
    }
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.resetCurrentPage();
    this.onFormSubmit();
  }

  async onFormSubmit() {
    this.isLoading = true;
    this.formSubmitCalled = true;
    try {
      let allData = [];
      let currentPage = 1;

      while (true) {
        const data = await this.fetchData(currentPage);
        if (data.length === 0) {
          break;
        }
        allData = [...allData, ...data];
        currentPage++;
      }

      this.localData = allData;
      this.totalPages = Math.ceil(this.localData.length / this.itemsPerPage);
  
      if (this.formSubmitCalled) {
        this.generateTable(this.localData);
      }
    } catch (error) {
      console.error('Error generating report:', error);
      this.isLoading = false;
    }
  }

  async fetchData(page: number): Promise<any[]> {
    const formValues = this.workflowReportForm.value;
  
    let itemsPerPage = this.itemsPerPage;
    const workflowType = formValues.workflowType === 'all' ? undefined : formValues.workflowType;
    const selectedUser = formValues.user;
    const dateStartedAfter = formValues.dateStartedAfter;
    const dateStartedBefore = formValues.dateStartedBefore;
  
    try {
      const responseData: any = await this.customReportsService.getReportTasks(
        itemsPerPage,
        page,
        workflowType,
        selectedUser,
        dateStartedAfter,
        dateStartedBefore
      );
      const data: any[] = responseData.data;
      this.localData = data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  onFormReset(_) {
    this.workflowReportForm.reset({
      workflowType: 'all',
      itemsPerPage: 50
    });
    this.showDataTable = false;
    this.showTaskMessage = false;
    this.isLoading = false;
    this.formSubmitCalled = false;
    this.resetCurrentPage();
    this.tableRows = [];
    this.localData = [];
  }
}
