<h2 mat-dialog-title>
  {{ 'TAGS.DIALOG-TITLE' | translate }} {{ fileName }}
</h2>

<mat-dialog-content class="adf-tags-dialog__dialog-content">
  <form novalidate>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'TAGS.ADD-TAG-BUTTON-PLACEHOLDER' | translate }}</mat-label>
      <input type="text" matInput [(ngModel)]="newTag" name="tagInput">
    </mat-form-field>
    <button matSuffix color="primary" (click)="addTag()">
      {{ 'TAGS.ADD-TAG-BUTTON' | translate }}
    </button>
    <div class="wfmics-user-chips">
      <span *ngFor="let tag of tags">
        <mat-icon (click)="removeTag(tag)">cancel</mat-icon>{{ tag.tag }}
      </span>
    </div>
  </form>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ 'TAGS.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>