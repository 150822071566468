<adf-login
  [copyrightText]="''"
  providers="ECM"
  successRoute="/personal-files"
  logoImageUrl="./assets/images/neocom-logo.svg"
  backgroundImageUrl="./assets/images/Wallpaper-BG-new.svg"
  [showRememberMe]="false"
  [showLoginActions]="false"
>
</adf-login>

<!--Old [copyrightText], removed for the momenet-->
<!--'application.copyright' | adfAppConfig | translate-->