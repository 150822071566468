<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE_ADMIN.MENU.CREATE_REGISTRY_BOOK.TITLE"></adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="create-registry-book-main-content">
      <form [formGroup]="archivesForm" #formDirective="ngForm" novalidate>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVES_LIST' | translate }}</mat-label>
            <mat-select formControlName="selectedArchive" (selectionChange)="onChangeArchive($event)">
              <mat-option *ngFor="let archive of archivesFiltered" [value]="archive.id">{{ archive.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.REGISTRY_TITLE' | translate }}</mat-label>
            <input matInput formControlName="title" name="title" type="text" />
            <mat-hint>{{ 'APP.ARCHIVE_ADMIN.TITLE_HINT' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.REGISTRY_YEAR' | translate }}</mat-label>
            <input matInput formControlName="year" name="year" type="number" maxlength="4" />
            <mat-hint>{{ 'APP.ARCHIVE_ADMIN.YEAR_HINT' | translate }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="form-footer">
          <button mat-raised-button (click)="createRegistryBook()">{{ 'APP.ARCHIVE.CREATE' | translate }}</button>
        </div>
      </form>
      <div *ngIf="registries && registries.length > 0" class="table">
        <adf-datatable 
          [rows]="registries"
          [columns]="registriesSchema"
          [actions]="true"
          (showRowActionsMenu)="onShowRowActionsMenu($event)"
          (executeRowAction)="onExecuteRowAction($event)">
        </adf-datatable>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
