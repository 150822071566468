import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  debounce,
  Constants as ConstantsShared,
  PageLayoutComponent,
  LleidaService,
  PageLayoutContentComponent,
  PageLayoutHeaderComponent
} from '@alfresco/aca-shared';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Person } from '@alfresco/js-api';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AppStore, SnackbarErrorAction, getUserList, getUserProfile } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslationService } from '@alfresco/adf-core';
import { Constants } from '../constants';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbModule,
    PageLayoutComponent,
    PageLayoutContentComponent,
    PageLayoutHeaderComponent,
    MatButtonModule,
    MatButtonToggleModule,
    MatDividerModule,
    TranslateModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './view-processes.component.html',
  styleUrls: ['./view-processes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-view-processes'
})
export class ViewProcessesComponent implements OnInit {
  
  inProcess: string = 'inProcess';
  currentSort: string = 'date';
  sortDateAsc: boolean = false;
  showFilters: boolean = false;
  users: Person[] = [];

  filterForm = new FormGroup({
    email: new FormControl('')
  });
  dataRequestDebounce: Function;
  userName: string;
  haveFilterChange: boolean = false;
  isLoading: boolean = false;

  currentPage: number = 1;
  selectedPage: number = 1;
  numberOfPages: number = 1;

  processes: any[] = [];

  constructor(
    private store: Store<AppStore>,
    private translation: TranslationService,
    private lleidaService: LleidaService
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.store.select(getUserProfile).subscribe((user) => {
      this.userName = user.id;
      this.onFormChange(null);
    });
    this.store.select(getUserList).subscribe((userList) => {
      this.users = userList;
    });
  }

  onFilterFormChange(event) {
    this.haveFilterChange = true;
    this.onFormChange(event);
  }

  sortProcessesByDate(processes: any[], sortAsc: boolean): any[] {
    return processes.sort((a, b) => {
      const dateA = Number(a.signature_start_date);
      const dateB = Number(b.signature_start_date);
      return sortAsc ? dateA - dateB : dateB - dateA;
    });
  }

  convertToUnixTimestamp(offsetMilliseconds: number = 0): number {
    const date = new Date();
    return Math.floor((date.getTime() + offsetMilliseconds) / 1000);
  }

  convertFromUnixTimestamp(unixTimestamp: number): string {
    if (!unixTimestamp) {
      return '';
    }
    const date = new Date(unixTimestamp * 1000);

    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
  }

  loadData = () => {
    if (!this.userName) {
      return;
    }

    this.isLoading = this.processes.length === 0;

    const user = 'neocom';
    const password = 'VCoWdNbz1GSD5qEjdgvJUSJ2mUFpHjRf';
    const oneDayInMs = 24 * 60 * 60 * 1000;

    // Use (- minus) for days ago and use (+ plus) for days in the future
    const startDate = this.convertToUnixTimestamp(-30 * oneDayInMs); // 30 days ago
    const endDate = this.convertToUnixTimestamp(oneDayInMs); // 1 day from now

    let statusFilter: string;
    switch (this.inProcess) {
      case 'inProcess':
        statusFilter = 'ready';
        break;
      case 'signed':
        statusFilter = 'signed';
        break;
      case 'error':
        statusFilter = 'failed';
        break;
      case 'cancelled':
        statusFilter = 'cancelled';
        break;
      default:
        statusFilter = 'ready';
        break;
    }

    const emailFilterValue = this.filterForm.get('email').value;

    this.lleidaService
      .getSignatureList(user, password, startDate, endDate, statusFilter)
      .then((data) => {
        const signatureList = data.signature;
        if (!signatureList) {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          return;
        }

        const filteredSignatures = signatureList.filter((signature) => {
          return signature.signature_status === statusFilter &&
            (!emailFilterValue || signature.signatories.some(signatory => signatory.email.includes(emailFilterValue)));
        });

        if (filteredSignatures.length === 0) {
          this.processes = [];
          this.numberOfPages = 1;
        } else {
          this.processes = this.sortProcessesByDate(filteredSignatures, this.sortDateAsc);
    
          this.numberOfPages = Math.ceil(filteredSignatures.length / Constants.LIST_PAGE_ITEM_COUNT);
          if (this.currentPage > this.numberOfPages) {
            this.currentPage = this.numberOfPages;
            this.selectedPage = this.currentPage;
          }
        }

        console.log(this.processes)
        this.isLoading = false;
      })
      .catch((error) => {
        console.error('Error fetching signature list:', error);
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));

        this.processes = [];
        this.numberOfPages = 1;
        this.isLoading = false;
      });

    this.currentPage = this.selectedPage;
  };

  onFormChange(_) {
    if (!this.dataRequestDebounce) this.dataRequestDebounce = debounce(ConstantsShared.CONTINUED_TYPING_THRESHOLD_VALUE);
    this.dataRequestDebounce(this.loadData);
  }

  onFormEnter(event) {
    event.preventDefault();
    this.onFormChange(event);
  }

  onSortByDate(_) {
    if (this.currentSort !== 'date') {
      this.currentSort = 'date';
    } else {
      this.sortDateAsc = !this.sortDateAsc;
    }

    this.processes = this.sortProcessesByDate(this.processes, this.sortDateAsc);
    console.log(this.processes);
  }

  onToggleShowFilters(event) {
    this.showFilters = !this.showFilters;
    if (this.haveFilterChange) {
      this.onFormChange(event);
    }
  }

  onResetFilters(_) {
    this.filterForm.reset();
    this.haveFilterChange = false;
    this.onFormChange(null);
  }

  getVisibleProcesses(): any[] {
    const startIndex = (this.currentPage - 1) * Constants.LIST_PAGE_ITEM_COUNT;
    const endIndex = Math.min(startIndex + Constants.LIST_PAGE_ITEM_COUNT, this.processes.length);
    return this.processes.slice(startIndex, endIndex);
  }

  getProcessIconURL(): string {
    return 'assets/images/neocom/signature-process.webp';
  }

  onClickProcess(processId: string) {
    // const processIdShort = processId.substring('activiti$'.length);
    // const url = `/lleida/process-details/${taskIdShort}`;
    // this.router.navigateByUrl(url);

    return processId; // REMOVE ON FIX | Fix method on updating new component
  }

  onJumpToPage() {
    if (this.selectedPage >= 1 && this.selectedPage <= this.numberOfPages) {
      this.onFormChange(null);
    } else {
      this.selectedPage = this.currentPage;
    }
  }

  onNextPage() {
    if (this.currentPage < this.numberOfPages) {
      this.currentPage++;
      this.selectedPage = this.currentPage;
      this.onFormChange(null);
    }
  }

  onPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.selectedPage = this.currentPage;
      this.onFormChange(null);
    }
  }
}
