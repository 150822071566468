/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { NodesApiService } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@alfresco/adf-core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { ContentApiService } from '@alfresco/aca-shared';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SiteService } from '@alfresco/aca-shared';
import { Node } from '@alfresco/js-api';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './create-new-subject.dialog.html',
  styleUrls: ['./create-new-subject.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'create-new-subject-dialog' }
})
export class CreateNewSubjectDialogComponent implements OnInit {

  sites: any[] = [];
  files: any[] = [];
  node: Node;
  fileName: string;
  result: boolean = false;

  newSubjectForm: FormGroup;
  documentsFolder: any = { id: '', name: '', children: [] };

  private folderStateCache: Map<string, any[]> = new Map();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CreateNewSubjectDialogComponent>,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private contentApiService: ContentApiService,
    private nodesApiService: NodesApiService,
    private siteService: SiteService,
    private router: Router
  ) {
    this.node = data.node.entry;
    this.fileName = data.node.entry.name;
  }

  async ngOnInit() {
    this.newSubjectForm = new FormGroup({
      subjectTitle: new FormControl(this.removePdfExtension(this.fileName), Validators.required),
      site: new FormControl(undefined, Validators.required),
      file: new FormControl(undefined, Validators.required)
    });

    try {
      this.sites = await this.siteService.listSites();
    } catch (error) {
      this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.SITES-ERROR');
    }
  }

  async onSiteClick(site: any): Promise<void> {
    this.newSubjectForm.get('site')?.setValue(site.id);

    this.documentsFolder = { id: '', name: '', children: [] };
    this.files = [];

    const activeSiteId = site.id;
    if (!site.documentsFolder || !site.documentsFolder.children.length) {
      try {
        await this.fetchDocumentLibraryChildren(site);
      } catch (error) {
        this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.FETCH-CHILDREN-ERROR');
        return;
      }
    }

    if (activeSiteId === this.newSubjectForm.get('site')?.value) {
      this.documentsFolder = site.documentsFolder || { id: '', name: '', children: [] };
      this.files = this.documentsFolder.children || [];
      this.newSubjectForm.get('file')?.setValue(this.documentsFolder.id);
      this.collapseAllSubChildren(this.documentsFolder.children || []);
    }
  }

  onDocumentsClick(): void {
    this.newSubjectForm.get('file')?.setValue(this.documentsFolder.id);

    this.documentsFolder.isExpanded = true;
    this.files = [...this.documentsFolder.children];
    this.collapseAllSubChildren(this.documentsFolder.children);
  }

  async onFileClick(folder: any): Promise<void> {
    this.newSubjectForm.get('file')?.setValue(folder.id);

    folder.isExpanded = !folder.isExpanded;
    if (folder.isExpanded) {
      if (!this.folderStateCache.has(folder.id)) {
        const children = await this.getNodeChildren(folder.id);
        folder.children = this.filterAndMapChildren(children);
        this.folderStateCache.set(folder.id, folder.children);
      }
      this.files = [...this.files.filter(f => f.id !== folder.id), ...this.folderStateCache.get(folder.id)];
    } else {
      this.files = this.files.filter(f => f.id !== folder.id);
      this.collapseAllSubChildren(folder.children);
    }
  }

  async createNewSubject(): Promise<void> {
    const title = this.newSubjectForm.get('subjectTitle')?.value;
    const folderRoute = this.newSubjectForm.get('file')?.value;

    if (title && folderRoute) {
      this.nodesApiService.createFolder(folderRoute, { name: title })
        .subscribe({
          next: async (createdFolder: Node) => {
            try {
              const documentId = this.node.id;
              await this.contentApiService
                .copyNode(
                  documentId,
                  createdFolder.id,
                  this.removePdfExtension(this.fileName)
                ).toPromise();

              this.closeDialog();
              this.redirectToNewFolder(createdFolder.id);
            } catch (error) {
              this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.COPY-DOCUMENT-ERROR');
            }
          },
          error: () => {
            this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.CREATE-FOLDER-ERROR');
          }
        });
    } else {
      this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.MISSING-DETAILS');
    }
  }

  closeDialog() {
    this.dialogRef.close(this.result);
  }

  private redirectToNewFolder(folderId: string): void {
    const newFolderRoute = `/libraries/${folderId}`;
    this.router.navigateByUrl(newFolderRoute).catch(() => {
      this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.REDIRECT-FOLDER-ERROR');
    });
  }

  private async fetchDocumentLibraryChildren(site: any): Promise<void> {
    try {
      if (!site.documentsFolder || !site.documentsFolder.id) {
        const docLibrary = (await this.getNodeChildren(site.guid))
          .find(entry => entry.entry.name === 'documentLibrary' && entry.entry.isFolder);

        site.documentsFolder = docLibrary ? {
          id: docLibrary.entry.id,
          name: 'Documents',
          children: []
        } : { id: '', name: '', children: [] };
      }

      if (site.documentsFolder.id) {
        const children = await this.getNodeChildren(site.documentsFolder.id);
        site.documentsFolder.children = this.filterAndMapChildren(children);
      }
    } catch (error) {
      this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.FETCH-CHILDREN-ERROR');
    }
  }

  private async getNodeChildren(nodeId: string): Promise<any[]> {
    let allChildren: any[] = [];
    let skipCount = 0;
    const maxItems = 100;
  
    try {
      let response;
      do {
        response = await this.contentApiService.getNodeChildren(nodeId, { maxItems, skipCount }).toPromise();
        allChildren = allChildren.concat(response.list.entries);
        skipCount += maxItems;
      } while (response.list.pagination.hasMoreItems);
    } catch (error) {
      this.showSnackbarError('CREATE-NEW-SUBJECT.ERROR.FETCH-CHILDREN-ERROR');
    }
    return allChildren;
  }

  private collapseAllSubChildren(children: any[]): void {
    children.forEach(child => {
      child.isExpanded = false;
      if (child.children && child.children.length > 0) {
        this.collapseAllSubChildren(child.children);
      }
    });
  }

  private filterAndMapChildren(entries: any[]): any[] {
    return entries
      .filter(entry => entry.entry.isFolder)
      .map(entry => ({
        id: entry.entry.id,
        name: entry.entry.name,
        children: []
      }));
  }

  private showSnackbarError(translationKey: string) {
    this.store.dispatch(new SnackbarErrorAction(this.translation.instant(translationKey)));
  }

  private removePdfExtension(fileName: string): string {
    return fileName.endsWith('.pdf') ? fileName.slice(0, -4) : fileName;
  }
}
