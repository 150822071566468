import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Utils } from '../utils'
import { AppStore, SnackbarWarningAction, SnackbarErrorAction, SnackbarInfoAction, getUserList, getGroupList } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { WorkflowService, StartWorkflowPayload, StartMRADPayload, StartGARDPayload, StartGRDPayload, StartMLADPayload, ContentApiService, PageLayoutComponent, PageLayoutHeaderComponent, PageLayoutContentComponent } from '@alfresco/aca-shared';
import { Observable, } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Node, Person } from '@alfresco/js-api';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NeocomDocumentSelectorComponent } from '../../neocom-document-selector/neocom-document-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    MatAutocompleteModule,
    MatIconModule,
    NeocomDocumentSelectorComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-start-workflow',
  templateUrl: './start-workflow.component.html',
  styleUrls: ['./start-workflow.component.scss']
})
export class StartWorkflowComponent implements OnInit {

  workflowDefinitions: any[];
  priorityOptions: any[];
  today = moment();
  users: Person[];
  groups: any[];
  usersFilteredMRAD: Observable<Person[]>;
  usersSelectedMRAD: Person[] = [];
  groupsFilteredGRD: Observable<any[]>;
  groupsFilteredGARD: Observable<any[]>;
  usersFilteredMLADL1: Observable<Person[]>;
  usersSelectedMLADL1: Person[] = [];
  usersFilteredMLADL2: Observable<Person[]>;
  usersSelectedMLADL2: Person[] = [];
  usersFilteredMLADL3: Observable<Person[]>;
  usersSelectedMLADL3: Person[] = [];
  usersFilteredMLADL4: Observable<Person[]>;
  usersSelectedMLADL4: Person[] = [];
  usersFilteredMLADL5: Observable<Person[]>;
  usersSelectedMLADL5: Person[] = [];
  groupsFilteredMLAD: Observable<any[]>;
  mladLevelCount: number = 1;
  mladHaveGroup: boolean = false;

  initiallySelectedDocuments: Node[] = [];
  selectedDocuments: Node[] = [];

  actionStarted: boolean = false;

  workflowForm = new FormGroup({
    workflowDefinition: new FormControl(undefined),
    title: new FormControl(''),
    dateDue: new FormControl(undefined),
    priority: new FormControl('2'),
    additionalDescription: new FormControl(''),
    sendEmailNotifications: new FormControl(true),

    // workflow-specific
    usersMRAD: new FormControl(''),
    groupGRD: new FormControl(''),
    groupGARD: new FormControl(''),
    groupGARDpercent: new FormControl(50),
    usersMLADL1: new FormControl(''),
    usersMLADL1percent: new FormControl(50),
    usersMLADL2: new FormControl(''),
    usersMLADL2percent: new FormControl(50),
    usersMLADL3: new FormControl(''),
    usersMLADL3percent: new FormControl(50),
    usersMLADL4: new FormControl(''),
    usersMLADL4percent: new FormControl(50),
    usersMLADL5: new FormControl(''),
    usersMLADL5percent: new FormControl(50),
    groupMLAD: new FormControl(''),
  });

  constructor(
    private store: Store<AppStore>,
    private translation: TranslationService,
    private contentApi: ContentApiService,
    private workflowService: WorkflowService,
    private route: ActivatedRoute,
  ) {
    this.workflowDefinitions = Utils.getWorkflowDefinitions(this.translation);
    this.priorityOptions = Utils.getPriorityOptions(this.translation).slice(1);

    translation.translate.onLangChange.subscribe(() => {
      this.workflowDefinitions = Utils.getWorkflowDefinitions(this.translation);
      this.priorityOptions = Utils.getPriorityOptions(this.translation).slice(1);
    });
  }

  async ngOnInit() {
    this.store.select(getUserList).subscribe((userList) => {
      this.users = userList;
    });
    this.store.select(getGroupList).subscribe((groupList) => {
      this.groups = groupList;
    });

    this.usersFilteredMRAD = this._generateUserFilterPipe('usersMRAD', this.usersSelectedMRAD);
    this.groupsFilteredGRD = this._generateGroupFilterPipe('groupGRD');
    this.groupsFilteredGARD = this._generateGroupFilterPipe('groupGARD');
    this.usersFilteredMLADL1 = this._generateUserFilterPipe('usersMLADL1', this.usersSelectedMLADL1);
    this.usersFilteredMLADL2 = this._generateUserFilterPipe('usersMLADL2', this.usersSelectedMLADL2);
    this.usersFilteredMLADL3 = this._generateUserFilterPipe('usersMLADL3', this.usersSelectedMLADL3);
    this.usersFilteredMLADL4 = this._generateUserFilterPipe('usersMLADL4', this.usersSelectedMLADL4);
    this.usersFilteredMLADL5 = this._generateUserFilterPipe('usersMLADL5', this.usersSelectedMLADL5);
    this.groupsFilteredMLAD = this._generateGroupFilterPipe('groupMLAD');

    if (this.route.snapshot.queryParams.id) {
      try {
        const node = await this.contentApi.getNodeInfo(this.route.snapshot.queryParams.id).toPromise();
        this.initiallySelectedDocuments = [ node ];
      } catch (_) {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.NODE_INFO_ERROR')));
      }
    }
  }

  onFormSubmit(event) {
    if (this.actionStarted) {
      return;
    }

    // input validation; only 'title' and 'assignees' are required
    const title = this.workflowForm.get('title').value;
    if (!title) {
      this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_TITLE')));
      return;
    }

    if (this.workflowDefinition === 'multipleReviewArchDoc') {
      if (this.usersSelectedMRAD.length === 0) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_USERS')));
        return;
      }
    } else if (this.workflowDefinition === 'multipleLevelApproveDoc') {
      if (this.usersSelectedMLADL1.length === 0) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_USERS_LEVEL_1')));
        return;
      }
      if (this.mladLevelCount >= 2 && this.usersSelectedMLADL2.length === 0) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_USERS_LEVEL_PLUS')));
        return;
      }
      if (this.mladLevelCount >= 3 && this.usersSelectedMLADL3.length === 0) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_USERS_LEVEL_PLUS')));
        return;
      }
      if (this.mladLevelCount >= 4 && this.usersSelectedMLADL4.length === 0) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_USERS_LEVEL_PLUS')));
        return;
      }
      if (this.mladLevelCount >= 5 && this.usersSelectedMLADL5.length === 0) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_USERS_LEVEL_PLUS')));
        return;
      }
    } else if (this.workflowDefinition === 'groupReceiveDoc') {
      if (!this.workflowForm.get('groupGRD').value) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_GROUP')));
        return;
      }
    } else if (this.workflowDefinition === 'groupApproveRejectDoc') {
      if (!this.workflowForm.get('groupGARD').value) {
        this.store.dispatch(new SnackbarWarningAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.MISSING_ASSIGNEES_GROUP')));
        return;
      }
    }

    this.actionStarted = true;

    const payload = this._generateWorkflowPayload();

    this.workflowService.startWorkflow(payload)
      .then(() => {
        this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.WORKFLOW.START_WORKFLOW.STARTED_SUCCESSFULLY')));

        this.onFormReset(event);

        this.actionStarted = false;
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        
        this.actionStarted = false;
      });
  }

  onFormReset(_) {
    this.workflowForm.reset();
    this.workflowForm.get('priority').setValue('2');
    this.workflowForm.get('sendEmailNotifications').setValue(true);
    this.workflowForm.get('groupGARDpercent').setValue(50);
    this.workflowForm.get('usersMLADL1percent').setValue(50);
    this.workflowForm.get('usersMLADL2percent').setValue(50);
    this.workflowForm.get('usersMLADL3percent').setValue(50);
    this.workflowForm.get('usersMLADL4percent').setValue(50);
    this.workflowForm.get('usersMLADL5percent').setValue(50);
  }

  get workflowDefinition() {
    return this.workflowForm.get('workflowDefinition').value;
  }

  onAutocompleteUsersSelect(formControlName: string, arrayReference: Person[]) {
    const username = this.workflowForm.get(formControlName).value;
    const user = this.users.find(el => el.id === username);
    if (!user)
      return;
    const existingUser = arrayReference.find(el => el.id === username);
    if (existingUser)
      return;
    arrayReference.push(user);
    this.workflowForm.get(formControlName).setValue('');
  }

  onAutocompleteUsersMRADSelect(_) {
    this.onAutocompleteUsersSelect('usersMRAD', this.usersSelectedMRAD);
  }

  onAutocompleteUsersMLADL1Select(_) {
    this.onAutocompleteUsersSelect('usersMLADL1', this.usersSelectedMLADL1);
  }

  onAutocompleteUsersMLADL2Select(_) {
    this.onAutocompleteUsersSelect('usersMLADL2', this.usersSelectedMLADL2);
  }

  onAutocompleteUsersMLADL3Select(_) {
    this.onAutocompleteUsersSelect('usersMLADL3', this.usersSelectedMLADL3);
  }

  onAutocompleteUsersMLADL4Select(_) {
    this.onAutocompleteUsersSelect('usersMLADL4', this.usersSelectedMLADL4);
  }

  onAutocompleteUsersMLADL5Select(_) {
    this.onAutocompleteUsersSelect('usersMLADL5', this.usersSelectedMLADL5);
  }

  onRemoveUsersSelected(username, arrayReference: Person[]) {
    const findIndex = arrayReference.findIndex(el => el.id === username);
    if (findIndex !== -1) {
      arrayReference.splice(findIndex, 1);
    }
  }

  onRemoveUsersSelectedMRAD(_, username) {
    this.onRemoveUsersSelected(username, this.usersSelectedMRAD);
  }

  onRemoveUsersSelectedMLADL1(_, username) {
    this.onRemoveUsersSelected(username, this.usersSelectedMLADL1);
  }

  onRemoveUsersSelectedMLADL2(_, username) {
    this.onRemoveUsersSelected(username, this.usersSelectedMLADL2);
  }

  onRemoveUsersSelectedMLADL3(_, username) {
    this.onRemoveUsersSelected(username, this.usersSelectedMLADL3);
  }

  onRemoveUsersSelectedMLADL4(_, username) {
    this.onRemoveUsersSelected(username, this.usersSelectedMLADL4);
  }

  onRemoveUsersSelectedMLADL5(_, username) {
    this.onRemoveUsersSelected(username, this.usersSelectedMLADL5);
  }

  onAddMLADLevel(_) {
    this.mladLevelCount++;
    if (this.mladLevelCount > 5)
      this.mladLevelCount = 5;
  }

  onRemoveMLADLevel(_) {
    const removedLevel = this.mladLevelCount;

    this.mladLevelCount--;
    if (this.mladLevelCount < 1)
      this.mladLevelCount = 1;

    switch (removedLevel) {
      case 2:
        this.usersSelectedMLADL2 = [];
        this.workflowForm.get('usersMLADL2percent').setValue(50);
        break;
      case 3:
        this.usersSelectedMLADL3 = [];
        this.workflowForm.get('usersMLADL3percent').setValue(50);
        break;
      case 4:
        this.usersSelectedMLADL4 = [];
        this.workflowForm.get('usersMLADL4percent').setValue(50);
        break;
      case 5:
        this.usersSelectedMLADL5 = [];
        this.workflowForm.get('usersMLADL5percent').setValue(50);
        break;
    }
  }

  onAddMLADGroup(_) {
    this.mladHaveGroup = true;
  }

  onRemoveMLADGroup(_) {
    this.workflowForm.get('groupMLAD').setValue('');
    this.mladHaveGroup = false;
  }

  onDocumentSelectorSelectionChanged(newSelection: Node[]) {
    this.selectedDocuments = newSelection;
  }

  onDocumentSelectorNavigate(index: number) {
    const node = this.selectedDocuments[index];
    const url = `#/libraries/${node.parentId}/(viewer:view/${node.id})?location=%2Flibraries%2F${node.parentId}`;
    window.open(url, '_blank');
  }

  formatDate(date: Date): string {
    if (!date)
      return '/';
    
    let result = date.toString();
    const index = result.lastIndexOf(' (');
    if (index !== -1)
      result = result.substring(0, index);
    
    return result;
  }

  private _generateWorkflowPayload(): StartWorkflowPayload {
    if (this.workflowDefinition === 'multipleReviewArchDoc') {
      return this._generateWorkflowPayloadMRAD();
    } else if (this.workflowDefinition === 'multipleLevelApproveDoc') {
      return this._generateWorkflowPayloadMLAD();
    } else if (this.workflowDefinition === 'groupReceiveDoc') {
      return this._generateWorkflowPayloadGRD();
    } else if (this.workflowDefinition === 'groupApproveRejectDoc') {
      return this._generateWorkflowPayloadGARD();
    }
    return null;
  }

  private _generateWorkflowPayloadMRAD(): StartMRADPayload {
    const assignees = this.usersSelectedMRAD.map(el => el.id);
    const [ documents, description, due, priority, sendEmailNotifications, moreDescription ] = this._generateWorkflowPayloadCommon();
    return new StartMRADPayload(assignees, documents, description, due, priority, sendEmailNotifications, moreDescription);
  }

  private _generateWorkflowPayloadMLAD(): StartMLADPayload {
    const [ documents, description, due, priority, sendEmailNotifications, moreDescription ] = this._generateWorkflowPayloadCommon();
    let firstLevelAssignees: string[] = this.usersSelectedMLADL1.map(el => el.id),
        firstLevelApprovePercent: number = this.workflowForm.get('usersMLADL1percent').value,
        secondLevelAssignees: string[] = [],
        secondLevelApprovePercent: number = 50,
        thirdLevelAssignees: string[] = [],
        thirdLevelApprovePercent: number = 50,
        fourthLevelAssignees: string[] = [],
        fourthLevelApprovePercent: number = 50,
        fifthLevelAssignees: string[] = [],
        fifthLevelApprovePercent: number = 50,
        group: string = this.workflowForm.get('groupMLAD').value;
    
    if (this.mladLevelCount >= 2) {
      secondLevelAssignees = this.usersSelectedMLADL2.map(el => el.id);
      secondLevelApprovePercent = this.workflowForm.get('usersMLADL2percent').value;
    }
    if (this.mladLevelCount >= 3) {
      thirdLevelAssignees = this.usersSelectedMLADL3.map(el => el.id);
      thirdLevelApprovePercent = this.workflowForm.get('usersMLADL3percent').value;
    }
    if (this.mladLevelCount >= 4) {
      fourthLevelAssignees = this.usersSelectedMLADL4.map(el => el.id);
      fourthLevelApprovePercent = this.workflowForm.get('usersMLADL4percent').value;
    }
    if (this.mladLevelCount >= 5) {
      fifthLevelAssignees = this.usersSelectedMLADL5.map(el => el.id);
      fifthLevelApprovePercent = this.workflowForm.get('usersMLADL5percent').value;
    }

    return new StartMLADPayload(firstLevelAssignees, firstLevelApprovePercent, secondLevelAssignees, secondLevelApprovePercent, thirdLevelAssignees, thirdLevelApprovePercent, fourthLevelAssignees, fourthLevelApprovePercent, fifthLevelAssignees, fifthLevelApprovePercent, group, documents, description, due, priority, sendEmailNotifications, moreDescription);
  }

  private _generateWorkflowPayloadGRD(): StartGRDPayload {
    const group = this.workflowForm.get('groupGRD').value;
    const [ documents, description, due, priority, sendEmailNotifications, moreDescription ] = this._generateWorkflowPayloadCommon();
    return new StartGRDPayload(group, documents, description, due, priority, sendEmailNotifications, moreDescription);
  }

  private _generateWorkflowPayloadGARD(): StartGARDPayload {
    const group = this.workflowForm.get('groupGARD').value;
    const approvePercent = this.workflowForm.get('groupGARDpercent').value;
    const [ documents, description, due, priority, sendEmailNotifications ] = this._generateWorkflowPayloadCommon();  // note: this workflow has no "moreDescription"
    return new StartGARDPayload(group, approvePercent, documents, description, due, priority, sendEmailNotifications);
  }

  private _generateWorkflowPayloadCommon(): any[] {
    const documents = this.selectedDocuments.map(el => `workspace://SpacesStore/${el.id}`);
    const description = this.workflowForm.get('title').value;
    const due = this.workflowForm.get('dateDue').value;
    const priority = this.workflowForm.get('priority').value;
    const sendEmailNotifications = this.workflowForm.get('sendEmailNotifications').value;
    const moreDescription = this.workflowForm.get('additionalDescription').value;
    return [ documents, description, due, priority, sendEmailNotifications, moreDescription ];
  }

  private _generateUserFilterPipe(formControlName, selected) {
    return this.workflowForm.get(formControlName).valueChanges.pipe(
      startWith(''),
      map(value => this._filterUsers(value || '', selected)),
    );
  }
  
  private _generateGroupFilterPipe(formControlName) {
    return this.workflowForm.get(formControlName).valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroups(value || '')),
    );
  }

  private _filterUsers(value: string, selected: Person[]): Person[] {
    if (!this.users)
      return [];
    const filterValue = value.toLowerCase();
    return this.users.filter(person => (
      (person.firstName.toLowerCase().includes(filterValue) || (person.lastName && person.lastName.toLowerCase().includes(filterValue))) &&
      !Boolean(selected.find(person2 => person2.id === person.id))
    ));
  }
  
  private _filterGroups(value: string): object[] {
    if (!this.groups)
      return [];
    const filterValue = value.toLowerCase();
    return this.groups.filter(group => group['displayName'].toLowerCase().includes(filterValue) || group['id'].toLowerCase().includes(filterValue));
  }
}
