<h2 mat-dialog-title class="adf-create-new-subject-title-content">
  <span class="adf-cns-title-text">{{ 'CREATE-NEW-SUBJECT.TITLE' | translate }}</span>
  <mat-icon (click)="closeDialog()" class="adf-cns-close-icon">close</mat-icon>
</h2>

<mat-dialog-content class="adf-create-new-subject-dialog-content">
  <hr class="adf-cns-separation-line" />
  <form [formGroup]="newSubjectForm" novalidate>
    <mat-form-field appearance="standard" class="adf-cns-full-width">
      <mat-label>{{ 'CREATE-NEW-SUBJECT.SUBJECT-TITLE' | translate }}:</mat-label>
      <input matInput formControlName="subjectTitle" />
    </mat-form-field>

    <div class="adf-cns-sites-and-files-container">
      <div class="adf-cns-sites-container">
        <mat-label>{{ 'CREATE-NEW-SUBJECT.SITES' | translate }}:</mat-label>
        <div class="adf-cns-sites-list">
          <div 
            class="adf-cns-site-item" 
            *ngFor="let site of sites" 
            (click)="onSiteClick(site)"
            [class.selected]="site.id === newSubjectForm.get('site')?.value">
            <span>{{ site.title }}</span>
          </div>
        </div>
      </div>

      <div class="adf-cns-files-container">
        <mat-label>{{ 'CREATE-NEW-SUBJECT.FILES' | translate }}:</mat-label>
        <div class="adf-cns-files-list">
          <ul class="adf-cns-folder-list">
            <li
              *ngIf="documentsFolder.id"
              class="adf-cns-file-item"
              (click)="onDocumentsClick()"
              [class.selected]="documentsFolder.id === newSubjectForm.get('file')?.value">
              <mat-icon>folder_open</mat-icon>
              <span>{{ documentsFolder.name }}</span>
            </li>
            <ul *ngIf="documentsFolder.children.length > 0">
              <li *ngFor="let folder of documentsFolder.children">
                <div 
                  class="adf-cns-file-item" 
                  (click)="onFileClick(folder)"
                  [class.selected]="folder.id === newSubjectForm.get('file')?.value">
                  <mat-icon>{{ folder.isExpanded ? 'folder_open' : 'folder' }}</mat-icon>
                  <span>{{ folder.name }}</span>
                </div>
                <ul *ngIf="folder.isExpanded && folder.children.length > 0">
                  <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: folder.children }"></ng-container>
                </ul>
              </li>
            </ul>
          </ul>
          <ng-template #recursiveList let-children>
            <li *ngFor="let child of children">
              <div 
                class="adf-cns-file-item" 
                (click)="onFileClick(child)"
                [class.selected]="child.id === newSubjectForm.get('file')?.value">
                <mat-icon>{{ child.isExpanded ? 'folder_open' : 'folder' }}</mat-icon>
                <span>{{ child.name }}</span>
              </div>
              <ul *ngIf="child.isExpanded && child.children.length > 0">
                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: child.children }"></ng-container>
              </ul>
            </li>
          </ng-template>
        </div>
      </div>
    </div>
  </form>
  <hr class="adf-cns-separation-line" />
</mat-dialog-content>

<mat-dialog-actions class="adf-create-new-subject-dialog-buttons">
  <button
    mat-button
    color="primary"
    [disabled]="newSubjectForm.invalid"
    class="adf-dialog-action-button"
    (click)="createNewSubject()">
    {{ 'CREATE-NEW-SUBJECT.YES_LABEL' | translate }}
  </button>
  <button
    mat-button
    (click)="closeDialog()">
    {{ 'APP.GENERAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>