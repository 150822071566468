<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.LLEIDA.LLEIDA_SIGN.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="lleida-signature-main-content">
      <form [formGroup]="lleidaForm" novalidate (ngSubmit)="onFormSubmit()">
        <mat-form-field appearance="fill" id="lsf-lleida-definition" placeholder="">
          <mat-label>{{ 'APP.LLEIDA.LLEIDA_SIGN.SELECT_SIGNATURE_LABEL' | translate }}</mat-label>
          <mat-select formControlName="lleidaDefinition" name="lleidaDefinition">
            <mat-option *ngFor="let lleidaDefinition of lleidaDefinitions" [value]="lleidaDefinition.id">{{ lleidaDefinition.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div [hidden]="!lleidaDefinition" id="ls-main-input-container">
          <div class="lsmic-section">
            <mat-form-field appearance="standard" id="lsf-name" class="form-field" required>
              <mat-label>{{ 'APP.LLEIDA.LLEIDA_SIGN.NAME' | translate }}</mat-label>
              <input matInput formControlName="name" name="name" />
            </mat-form-field>
            <mat-form-field appearance="standard" id="lsf-surname" class="form-field" required>
              <mat-label>{{ 'APP.LLEIDA.LLEIDA_SIGN.SURNAME' | translate }}</mat-label>
              <input matInput formControlName="surname" name="surname" />
            </mat-form-field>
          </div>
          <div class="lsmic-section">
            <mat-form-field appearance="standard" id="lsf-email" class="form-field" required>
              <mat-label>{{ 'APP.LLEIDA.LLEIDA_SIGN.EMAIL' | translate }}</mat-label>
              <input matInput formControlName="email" name="email" type="email" />
            </mat-form-field>
          </div>
          <div class="lsmic-section" [hidden]="!shouldShowPhoneNumberSection()">
            <mat-form-field>
              <mat-label>{{ 'APP.LLEIDA.LLEIDA_SIGN.PHONE' | translate }}</mat-label>
              <input matInput formControlName="phone" name="phone" type="tel"/>
            </mat-form-field>
          </div>
          <div class="lsmic-section">
            <span class="lsmics-title">{{ 'APP.LLEIDA.LLEIDA_SIGN.DOCUMENTS' | translate }}</span>
            <div id="document-selector-container">
              <aca-neocom-document-selector
                [initialSelection]="initiallySelectedDocuments"
                [pdfOnly]="true"
                (selectionChanged)="onDocumentSelectorSelectionChanged($event)"
                (navigate)="onDocumentSelectorNavigate($event)">
              </aca-neocom-document-selector>
            </div>
          </div>
        </div>

        <div [hidden]="!lleidaDefinition" id="ls-footer">
          <button mat-button (click)="onFormReset($event)" type="button">{{ 'APP.ACTIONS.RESET' | translate }}</button>
          <button mat-raised-button type="submit" class="action-button" color="primary">
            {{ 'APP.LLEIDA.LLEIDA_SIGN.SIGN' | translate }}
          </button>
        </div>
      </form>
    </div>
  </aca-page-layout-content>
</aca-page-layout>